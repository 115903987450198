define('customisation-web-public/pods/book-addition/payment-centre/route', ['exports', 'customisation-web-public/config/environment', 'customisation-web-public/mixins/view-booking-route', 'customisation-web-public/models/booking-checkout'], function (exports, _environment, _viewBookingRoute, _bookingCheckout) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Route = Ember.Route;
    var inject = Ember.inject.service;
    var on = Ember.on;
    var get = Ember.get;
    var set = Ember.set;
    var assign = Ember.assign;
    var RSVP = Ember.RSVP;
    var SERVICES = _environment.default.SERVICES;
    exports.default = Route.extend(_viewBookingRoute.default, {
        ajax: inject(),
        session: inject(),
        stripe: inject(),

        _deactivate: on('deactivate', function () {
            this.get('stripe').close();
        }),

        beforeModel: function beforeModel() {
            return this.get('stripe').setup();
        },
        model: function model() {
            var bookingId = this.modelFor('book-addition');
            var paymentError = localStorage.getItem('paymentError');
            localStorage.removeItem('paymentError');

            return RSVP.hash({
                rootURL: _environment.default.rootURL,
                booking: this.fetchBooking(bookingId),
                errors: null,
                paymentError: paymentError
            });
        },
        afterModel: function afterModel(model, transition) {
            var booking = model.booking;
            var softPencilItems = booking.get('items').filter(function (i) {
                return get(i, 'status') === 'SOFT_PENCIL';
            });

            if (softPencilItems.length === 0) {
                transition.abort();

                this.transitionTo('book-addition.select-products', booking.get('id'));
            }
        },
        getFinishUrl: function getFinishUrl() {
            var location = window.location.href;
            var match = /^.*?\w\//.exec(location);
            var prefix = match ? match[0] : location;

            // const prefix = window.location.origin + window.location.pathname;
            return '' + prefix + this.get('router').generate('payment-callback');
        },
        checkoutBooking: function checkoutBooking(booking) {
            var checkoutData = _bookingCheckout.default.stringify(_bookingCheckout.default.create({
                items: booking.get('items').filter(function (i) {
                    return i.status === 'SOFT_PENCIL';
                }).map(function (i) {
                    return i.id;
                }),
                termsAccepted: true
            }));

            return this.get('ajax').post(SERVICES.bookings + '/bookings/' + booking.id + '/checkouts', checkoutData, _bookingCheckout.default);
        },
        cancelAdditionalItemsPayment: function cancelAdditionalItemsPayment(bookingId, checkoutId, error) {
            var _this = this;

            this.set('currentModel.inProgress', true);

            if (error) {
                localStorage.setItem('paymentError', error);
            }

            return this.get('ajax').post(SERVICES.bookings + '/bookings/' + bookingId + '/checkouts/' + checkoutId + '/cancel').catch(function (errors) {
                window.scrollTo(0, 0);

                _this.set('currentModel.errors', errors);
                return errors;
            }).finally(function () {
                _this.set('currentModel.inProgress', false);

                if (!_this.get('currentModel.errors')) {
                    _this.refresh();
                }
            });
        },


        actions: {
            makeStripePayment: function makeStripePayment(e) {
                e.preventDefault();
                console.log('Redirecting to stripe..');
                if (this.get('currentModel.inProgress')) {
                    return;
                }

                setTimeout(function () {
                    var _this2 = this;

                    this.set('currentModel.inProgress', true);

                    var booking = this.get('currentModel.booking');
                    var customer = this.get('session.currentCustomer');

                    if (booking.get('softPencilAmount') > 0) {
                        this.set('currentModel.errors', null);

                        this.checkoutBooking(booking).then(function (checkout) {
                            var successUrl = (_environment.default.API_HOST + _environment.default.clientRootURL + "/booking-confirm/").replace(/([^:]\/)\/+/g, "$1");
                            var cancelUrl = (_environment.default.API_HOST + _environment.default.clientRootURL).replace(/([^:]\/)\/+/g, "$1");
                            var itemsGroup = checkout.get('id');

                            var requestData = JSON.stringify({
                                clientId: booking.get('client.id'),
                                orderId: booking.get('id'),
                                itemsGroup: itemsGroup,
                                successUrl: successUrl,
                                cancelUrl: cancelUrl,
                                description: 'Fund It Now',
                                address1: customer.get('mainAddress.address1'),
                                postCode: customer.get('mainAddress.postcode'),
                                amount: checkout.get('amount'),
                                currency: booking.get('currency')
                            });

                            _this2.set('currentModel.inProgress', true);

                            _this2.get('ajax').rawRequest(SERVICES.payments + '/stripe/transaction-requests', {
                                method: 'POST',
                                contentType: 'application/json',
                                data: requestData
                            }).then(function (transactionJSON) {
                                var transaction = transactionJSON.attributes;

                                _this2.get('stripe').redirectToCheckout({
                                    sessionId: transaction.merchantRelatedData.CheckoutSessionId
                                }).then(function (result) {
                                    console.log("error = " + result);
                                    // If `redirectToCheckout` fails due to a browser or network
                                    // error, display the localized error message to your customer
                                    // using `result.error.message`.
                                });
                            });
                        }).catch(function (errors) {
                            window.scrollTo(0, 0);

                            _this2.set('currentModel.errors', errors);
                            return errors;
                        }).finally(function () {
                            return _this2.set('currentModel.inProgress', false);
                        });
                    } else {
                        // TODO: Handle free items.
                        /*
                        this.get('basket').checkout(paymentInfo)
                            .then(booking => this.transitionTo('booking-confirm', booking.id))
                            .catch(errors => {
                                this.set('currentModel.errors', errors);
                                return errors;
                            })
                            .finally(() => this.set('currentModel.inProgress', false));
                        */
                    }
                }, 30000);
            }
        }
    });
});