define('customisation-web-public/utils/collectors', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.flatMap = flatMap;
    exports.uniqueMap = uniqueMap;
    exports.hashMap = hashMap;

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var get = Ember.get;
    var typeOf = Ember.typeOf;

    /* global Set */

    function flatMap(array) {
        var fn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function (v) {
            return v;
        };

        return array.reduce(function (accum, item) {
            accum.pushObjects(fn(item));
            return accum;
        }, []);
    }

    function uniqueMap(array) {
        var fn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function (v) {
            return v;
        };

        return [].concat(_toConsumableArray(array.reduce(function (accum, item) {
            accum.add(fn(item));
            return accum;
        }, new Set())));
    }

    function hashMap(array) {
        var keyFn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function (v) {
            return get(v, 'id');
        };
        var valueFn = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function (v) {
            return v;
        };

        return array.reduce(function (accum, item) {
            var key = keyFn(item);
            var value = valueFn(item);

            if (typeOf(key) === 'array') {
                // If multiple keys - add each into the map.
                key.forEach(function (k) {
                    accum[k] = value;
                });
            } else {
                accum[key] = value;
            }

            return accum;
        }, {});
    }
});