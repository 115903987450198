define('customisation-web-public/pods/login/login-form/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component;
    var service = Ember.inject.service;
    exports.default = Component.extend({
        modal: service(),

        actions: {
            submit: function submit() {
                var _this = this;

                if (this.get('inProgress')) {
                    return false;
                }
                this.set('badCredentials', false);
                this.set('inProgress', true);

                this.get('authenticate')(this.getProperties('username', 'password')).then(function () {
                    if (_this.get('isDialog')) {
                        _this.get('modal').hide();
                    }
                }).catch(function () {
                    _this.set('badCredentials', true);
                    _this.set('password', '');
                    _this.set('inProgress', false);
                });

                return false;
            }
        }
    });
});