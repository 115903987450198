define('customisation-web-public/pods/faq/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Route = Ember.Route;
    var inject = Ember.inject.service;
    exports.default = Route.extend({
        project: inject(),

        model: function model() {
            return {
                website: this.get('project.data.website')
            };
        }
    });
});