define('customisation-web-public/pods/components/content-wrapper-design-3/component', ['exports', 'customisation-web-public/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component;
    var service = Ember.inject.service;
    var computed = Ember.computed;
    var on = Ember.on;
    var isPresent = Ember.isPresent;
    var $ = Ember.$;
    var rootURL = _environment.default.rootURL,
        clientRootURL = _environment.default.clientRootURL;
    exports.default = Component.extend({
        session: service(),
        modal: service(),
        basket: service(),
        project: service(),

        rootURL: rootURL,
        clientRootURL: clientRootURL,

        _setup: on('init', function () {
            this.set('showLoginLink', !isPresent(this.get('showLoginLink')) || this.get('showLoginLink'));
        }),

        _didInsertElement: on('didInsertElement', function () {}),

        groupsCount: computed('basket.data.bookingGroups.@each', function () {
            var groups = this.get('basket.data.bookingGroups');
            return groups && groups.length;
        }),
        emptyBasket: computed('groupsCount', function () {
            return this.get('groupsCount') === 0;
        }),

        actions: {
            showLoginModal: function showLoginModal() {
                this.get('modal').show('login-modal', {
                    title: 'Login'
                });
            },
            invalidateSession: function invalidateSession() {
                this.get('session').invalidate();
            }
        }
    });
});