define('customisation-web-public/pods/activate-customer/route', ['exports', 'customisation-web-public/config/environment', 'customisation-web-public/models/customer'], function (exports, _environment, _customer) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Route = Ember.Route;
    var service = Ember.inject.service;
    var RSVP = Ember.RSVP;
    var SERVICES = _environment.default.SERVICES;
    exports.default = Route.extend({
        ajax: service(),
        session: service(),

        model: function model(params, transition) {
            var _this = this;

            this.set('activationCode', params.code);

            var activationPromise = this.get('ajax').fetchModel(_customer.default, SERVICES.customers + '/activations/' + params.code).catch(function () {
                return RSVP.resolve(null);
            });

            activationPromise.then(function (customer) {
                if (!customer) {
                    return customer;
                }

                // Skip this step - if the session is authenticated and the customer is already active.
                if (_this.get('session.isAuthenticated') && customer.get('active')) {
                    _this.redirectAuthenticatedCustomer(transition);
                }

                return customer;
            });

            return RSVP.hash({
                customer: activationPromise
            });
        },
        serialize: function serialize() {
            // Not implemented intentionally because it's supposed to be called only directly by URL.
            return this._super.apply(this, arguments);
        },
        redirectAuthenticatedCustomer: function redirectAuthenticatedCustomer() {
            var transition = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

            if (transition) {
                transition.abort();
            }

            this.transitionTo('index');
        },


        actions: {
            activateCustomer: function activateCustomer(passwordObj) {
                var _this2 = this;

                var activationData = _customer.default.stringify(passwordObj);

                return this.get('ajax').post(SERVICES.customers + '/activations/' + this.get('activationCode'), activationData).then(function () {
                    _this2.get('session').authenticateCustomer({
                        username: _this2.get('currentModel.customer.email'),
                        password: passwordObj.confirmPassword
                    }).then(function () {
                        return _this2.redirectAuthenticatedCustomer();
                    });
                });
            }
        }
    });
});