define('customisation-web-public/models/booking', ['exports', 'big.js', 'ember-sf-jsonapi-model/mixins/jsonapi-model', 'customisation-web-public/models/customer', 'customisation-web-public/models/booking-group', 'customisation-web-public/models/client', 'customisation-web-public/utils/collectors'], function (exports, _big, _jsonapiModel, _customer, _bookingGroup, _client, _collectors) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var EmberObject = Ember.Object;
    var computed = Ember.computed;
    var copy = Ember.copy;
    var isPresent = Ember.isPresent;


    var Booking = EmberObject.extend(Ember.Copyable, {
        id: null,
        reference: null,
        created: null,
        bookingGroups: null,
        items: null,
        discountAmount: null,
        discountPercent: null,
        promotionAmount: null,
        promotionCode: null,
        channel: null,
        currency: null,
        amount: null,
        unpaidAmount: null,
        status: null,
        accessCode: null,
        customer: null,
        client: null,
        info: null,

        isAuthorised: computed('status', function () {
            return this.get('status') === 'AUTHORISED';
        }),
        isCancelled: computed('status', function () {
            return this.get('status') === 'CANCELLED';
        }),
        containsAdditionalItems: computed('groupItems.@each.itemsGroup', function () {
            return this.get('groupItems').find(function (i) {
                return isPresent(i.get('itemsGroup'));
            });
        }),

        sortedGroups: computed('bookingGroups.@each.id', function () {
            return this.get('bookingGroups').sortBy('id');
        }),
        groupItems: computed('bookingGroups.@each.id', function () {
            return (0, _collectors.flatMap)(this.get('bookingGroups'), function (bg) {
                return bg.get('items');
            });
        }),

        deliveryTotal: computed('groupItems.@each.product', function () {
            var result = this.get('groupItems').reduce(function (accum, item) {
                if (item.get('status') !== 'CANCELLED' && item.get('isDelivery')) {
                    accum = accum.plus(item.get('total'));
                }

                return accum;
            }, new _big.default(0));

            return Number(result);
        }),
        softPencilAmount: computed('groupItems', function () {
            var result = this.get('groupItems').reduce(function (accum, item) {
                if (item.get('isSoftPencil')) {
                    accum = accum.plus(item.get('total'));
                }

                return accum;
            }, new _big.default(0));

            return Number(result);
        }),
        softPencilDeliveryTotal: computed('groupItems.@each.product', function () {
            var result = this.get('groupItems').reduce(function (accum, item) {
                if (item.get('productGroup') === 'Deliveries' && item.get('isSoftPencil')) {
                    accum = accum.plus(item.get('price'));
                }

                return accum;
            }, new _big.default(0));

            return Number(result);
        }),

        fromJSON: function fromJSON() {
            var ret = copy(this, true);

            if (isPresent(this.get('bookingGroups'))) {
                ret.set('bookingGroups', _bookingGroup.default.convertArray(this.get('bookingGroups')));
            }

            return ret;
        }
    });

    Booking.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'bookings',
            relationships: {
                customer: _customer.default,
                client: _client.default
            }
        }
    });

    Booking.reopen({
        copy: function copy() {
            return Booking.create(this.getProperties(Object.keys(this)));
        }
    });

    exports.default = Booking;
});