define("customisation-web-public/utils/range", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.range = range;
    function range(from, to) {
        var ret = [];

        for (var i = from; i <= to; i++) {
            ret.push(i);
        }

        return ret;
    }
});