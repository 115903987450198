define('customisation-web-public/models/delivery-region', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model', 'customisation-web-public/models/project'], function (exports, _jsonapiModel, _project) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var EmberObject = Ember.Object;


    var DeliveryRegion = EmberObject.extend({
        id: null,
        project: null,
        name: null,
        countries: null
    });

    DeliveryRegion.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'delivery-regions',
            relationships: {
                project: _project.default
            }
        }
    });

    exports.default = DeliveryRegion;
});