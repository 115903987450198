define('customisation-web-public/pods/book/customize-product/booking-group', ['exports', 'customisation-web-public/utils/collectors', 'customisation-web-public/pods/book/customize-product/basket-item'], function (exports, _collectors, _basketItem) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var EmberObject = Ember.Object;
    var computed = Ember.computed;
    var isEmpty = Ember.isEmpty;


    var BookingGroup = EmberObject.extend({
        bookingGroup: null,
        product: null,
        customizationForms: null,
        extras: null,
        deliveries: null,
        customizationMissing: null,

        displayName: computed('bookingGroup.displayName', function () {
            return this.get('bookingGroup.displayName');
        }),
        bookingGroupItems: computed('bookingGroup.items.@each', function () {
            return this.get('bookingGroup.items');
        }),

        name: computed('displayName.name', function () {
            return this.get('displayName.name');
        }),
        subname: computed('displayName.subname', function () {
            return this.get('displayName.subname');
        }),

        items: computed('bookingGroupItems.@each', 'product', 'extras.@each', function () {
            var _this = this;

            var ret = [];
            var existingProducts = [];

            // Build used+available products hash map (id -> product).
            var productsMap = (0, _collectors.hashMap)(this.get('extras'));
            productsMap[this.get('product.id')] = this.get('product');

            // Add items/products currently present in the booking group.
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = this.get('bookingGroupItems')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var item = _step.value;

                    if (item.get('isDelivery')) {
                        continue;
                    }

                    var product = productsMap[item.get('product')];

                    ret.pushObject(_basketItem.default.create({
                        basketItem: item,
                        product: product,
                        quantity: item.get('quantity'),
                        minDeliveryPrice: this.minDeliveryPriceFor(product),
                        customizationForm: this.customizationFormFor(product)
                    }));

                    existingProducts.pushObject(product.get('id'));
                }

                // Add items/products that are available for this booking group.
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }

            var unusedExtras = this.get('extras').filter(function (e) {
                return isEmpty(e.get('availableWith')) || e.get('availableWith').includes(_this.get('product.id'));
            }).filter(function (e) {
                return !existingProducts.includes(e.get('id'));
            });
            var _iteratorNormalCompletion2 = true;
            var _didIteratorError2 = false;
            var _iteratorError2 = undefined;

            try {
                for (var _iterator2 = unusedExtras[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                    var extra = _step2.value;

                    ret.pushObject(_basketItem.default.create({
                        product: extra,
                        minDeliveryPrice: this.minDeliveryPriceFor(extra)
                    }));
                }

                // Sort the items: PRODUCT item first, then the rest ordered by price desc.
            } catch (err) {
                _didIteratorError2 = true;
                _iteratorError2 = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion2 && _iterator2.return) {
                        _iterator2.return();
                    }
                } finally {
                    if (_didIteratorError2) {
                        throw _iteratorError2;
                    }
                }
            }

            return ret.sort(function (a, b) {
                if (a.get('productType') === b.get('productType')) {
                    return b.get('product.price') - a.get('product.price');
                }

                return a.get('productType') === 'PRODUCT' ? -1 : 1;
            });
        }),
        mainItem: computed('items', function () {
            var _iteratorNormalCompletion3 = true;
            var _didIteratorError3 = false;
            var _iteratorError3 = undefined;

            try {
                for (var _iterator3 = this.get('items')[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                    var item = _step3.value;

                    if (item.get('isMain')) {
                        return item;
                    }
                }
            } catch (err) {
                _didIteratorError3 = true;
                _iteratorError3 = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion3 && _iterator3.return) {
                        _iterator3.return();
                    }
                } finally {
                    if (_didIteratorError3) {
                        throw _iteratorError3;
                    }
                }
            }

            return null;
        }),

        customizationFormFor: function customizationFormFor(product) {
            if (isEmpty(product.get('meta.customizationFormId'))) {
                return null;
            }

            var customizationFormsMap = (0, _collectors.hashMap)(this.get('customizationForms'));
            return customizationFormsMap[product.get('meta.customizationFormId')];
        },
        minDeliveryPriceFor: function minDeliveryPriceFor(product) {
            var deliveries = this.get('deliveries').filter(function (d) {
                return !isEmpty(d.get('availableWith')) && d.get('availableWith').includes(product.get('id'));
            });

            if (deliveries.length === 0) {
                return null;
            }

            return deliveries.reduce(function (min, d) {
                return Math.min(min, d.get('price'));
            }, 9999);
        },

        hasExtras: computed('items.@each', function () {
            return this.get('items').some(function (i) {
                return i.get('isExtra');
            });
        })
    });

    exports.default = BookingGroup;
});