define('customisation-web-public/pods/login/route', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Route = Ember.Route;
    var service = Ember.inject.service;
    exports.default = Route.extend(_unauthenticatedRouteMixin.default, {
        session: service(),

        actions: {
            authenticate: function authenticate(credentials) {
                return this.get('session').authenticateCustomer(credentials);
            }
        }
    });
});