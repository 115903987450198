define('customisation-web-public/pods/components/images-carousel/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component;
    var on = Ember.on;
    var $ = Ember.$;
    exports.default = Component.extend({
        tagName: 'div',
        classNames: [''],

        _didInsertElement: on('didInsertElement', function () {
            this.set('$element', $(this.get('element')).find('.carousel'));
            this.get('$element').carousel('cycle');
        }),
        _didDestroyElement: on('willDestroyElement', function () {
            //this.get('$element').slick('unslick');
        })
    });
});