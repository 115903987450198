define('customisation-web-public/authorizers/custom', ['exports', 'ember-simple-auth/authorizers/base'], function (exports, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var inject = Ember.inject.service;
    var isEmpty = Ember.isEmpty;
    exports.default = _base.default.extend({
        session: inject(),

        authorize: function authorize(data, block) {
            var accessToken = data['token'];

            if (this.get('session.isAuthenticated') && !isEmpty(accessToken)) {
                block('Authorization', 'Bearer ' + accessToken);
            }
        }
    });
});