define('customisation-web-public/models/booking-group', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model', 'customisation-web-public/models/basket-item'], function (exports, _jsonapiModel, _basketItem) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var EmberObject = Ember.Object;
    var computed = Ember.computed;


    var PRODUCT_GROUPS_ORDER = ['Products', 'Vouchers', 'Extras', 'Deliveries'];

    var BookingGroup = EmberObject.extend(Ember.Copyable, {
        bookingGroup: null,
        items: null,
        groupTotal: null,

        displayName: computed('bookingGroup', function () {
            return JSON.parse(this.get('bookingGroup'));
        }),
        id: computed('displayName', function () {
            return this.get('displayName.iter_index');
        }),
        sortedItems: computed('items', function () {
            return this.get('items').sort(function (i1, i2) {
                var createdDiff = i1.get('created') && i2.get('created') ? i1.get('created').getTime() - i2.get('created').getTime() : i1.get('created') ? 1 : -1;

                if (createdDiff !== 0) {
                    return createdDiff;
                }

                if (i1.get('productGroup') === i2.get('productGroup')) {
                    return i2.get('product.price') - i1.get('product.price');
                }

                return PRODUCT_GROUPS_ORDER.indexOf(i1.get('productGroup')) - PRODUCT_GROUPS_ORDER.indexOf(i2.get('productGroup'));
            });
        }),
        mainItem: computed('items.@each', function () {
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = this.get('items')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var item = _step.value;

                    if (item.get('productType') === 'PRODUCT' && item.get('productGroup') === 'Products') {
                        return item;
                    }
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }

            return null;
        }),
        containsSoftPencilItems: computed('items.@each', function () {
            return this.get('items').filter(function (i) {
                return i.get('status') === 'SOFT_PENCIL';
            }).length > 0;
        })
    });

    BookingGroup.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'booking-groups'
        },
        convert: function convert(json) {
            return BookingGroup.create({
                bookingGroup: json.bookingGroup,
                groupSubtotal: json.groupSubtotal,
                groupTotal: json.groupTotal,
                items: _basketItem.default.convertArray(json.items, json.bookingGroup)
            });
        },
        convertArray: function convertArray(jsonArray) {
            var _this = this;

            return jsonArray.map(function (item) {
                return _this.convert(item);
            });
        }
    });

    BookingGroup.reopen({
        copy: function copy() {
            return BookingGroup.create(this.getProperties(Object.keys(this)));
        }
    });

    exports.default = BookingGroup;
});