define('customisation-web-public/models/size', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model'], function (exports, _jsonapiModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var EmberObject = Ember.Object;
    var computed = Ember.computed;


    var Size = EmberObject.extend({
        name: null,
        description: null,
        url: null
    });

    Size.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'sizes'
        }
    });

    exports.default = Size;
});