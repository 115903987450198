define('customisation-web-public/pods/components/login-modal/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component;
    var on = Ember.on;
    var service = Ember.inject.service;
    exports.default = Component.extend({
        session: service(),
        modal: service(),

        _didInsert: on('didInsertElement', function () {
            var _this = this;

            this.get('modal.element').on('shown.bs.modal', function () {
                _this.$('#username').focus();
            });
        }),

        actions: {
            authenticate: function authenticate(credentials) {
                return this.get('session').authenticateCustomer(credentials, true);
            }
        }
    });
});