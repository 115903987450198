define('customisation-web-public/pods/booking-confirm/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'customisation-web-public/mixins/view-booking-route'], function (exports, _authenticatedRouteMixin, _viewBookingRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Route = Ember.Route;
    exports.default = Route.extend(_authenticatedRouteMixin.default, _viewBookingRoute.default, {
        confirmation: true
    });
});