define('customisation-web-public/models/website', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model'], function (exports, _jsonapiModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var EmberObject = Ember.Object;


    var Website = EmberObject.extend({
        webAddress: null,
        design: null,
        logoUrl: null,
        headerUrl: null,
        headerTitle: null,
        headerText: null,
        indexHeader: null,
        indexIntro: null,
        primaryColor: '#00704A',
        secondaryColor: '#F37737',
        contactEmail: null,
        contactPhone: null,
        faq: null,
        terms: null,
        carousel: null,
        customization: null
    });

    Website.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'websites'
        }
    });

    exports.default = Website;
});