define('customisation-web-public/instance-initializers/application-route', ['exports', 'ember-sf-modal/instance-initializers/application-route'], function (exports, _applicationRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _applicationRoute.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _applicationRoute.initialize;
    }
  });
});