define('customisation-web-public/pods/book/customize-product/route', ['exports', 'customisation-web-public/config/environment', 'customisation-web-public/models/product', 'customisation-web-public/models/customization-form', 'customisation-web-public/utils/urls', 'customisation-web-public/utils/collectors', 'customisation-web-public/pods/book/customize-product/booking-group'], function (exports, _environment, _product, _customizationForm, _urls, _collectors, _bookingGroup) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Route = Ember.Route;
    var service = Ember.inject.service;
    var on = Ember.on;
    var computed = Ember.computed;
    var copy = Ember.copy;
    var isPresent = Ember.isPresent;
    var RSVP = Ember.RSVP;
    var SERVICES = _environment.default.SERVICES;
    exports.default = Route.extend({
        ajax: service(),
        project: service(),
        basket: service(),

        _activate: on('activate', function () {
            this.set('bubbleDidTransition', true);

            this.get('basket').on('basketRefreshed', this, this.onBasketRefreshed);
        }),
        _deactivate: on('deactivate', function () {
            this.get('basket').off('basketRefreshed', this, this.onBasketRefreshed);
        }),

        clientId: computed('project.data.client.id', function () {
            return this.get('project.data.client.id');
        }),
        projectId: computed('project.data.id', function () {
            return this.get('project.data.id');
        }),

        beforeModel: function beforeModel(transition) {
            var _this = this;

            return this.get('basket').setup(true).then(function () {
                return _this.validateBasketState(transition);
            });
        },
        model: function model() {
            var _this2 = this;

            var productsPromise = this.fetchMainProducts();
            var extrasPromise = this.fetchExtraProducts();

            var bookingGroupsPromise = productsPromise.then(function (products) {
                return _this2.fetchCustomizationForms(products).then(function (customizationForms) {
                    return extrasPromise.then(function (extras) {
                        return _this2.fetchDeliveryProducts(extras).then(function (deliveries) {
                            return _this2.buildBookingGroups(products, customizationForms, extras, deliveries);
                        });
                    });
                });
            });

            return RSVP.hash({
                products: productsPromise,
                bookingGroups: bookingGroupsPromise
                // additionalProducts: this.fetchAdditionalProducts()
            });
        },
        validateBasketState: function validateBasketState(transition) {
            if (!this.get('basket.data.hasProducts')) {
                if (transition) {
                    transition.abort();
                }

                this.transitionTo('book.select-products');
            }
        },
        onBasketRefreshed: function onBasketRefreshed() {
            this.refresh();
            this.set('bubbleDidTransition', false);
        },
        fetchMainProducts: function fetchMainProducts() {
            var basketItems = (0, _collectors.flatMap)(this.get('basket.data.bookingGroups'), function (bg) {
                return bg.get('items');
            });
            var productsIds = (0, _collectors.uniqueMap)(basketItems, function (bi) {
                return bi.get('product');
            });

            return this.get('ajax').fetchList(_product.default, (0, _urls.buildFilterUrl)(SERVICES.products, {
                client: this.get('clientId'),
                id: productsIds
            }));
        },
        fetchCustomizationForms: function fetchCustomizationForms(products) {
            var formIds = products.filter(function (p) {
                return isPresent(p.get('meta.customizationFormId'));
            }).map(function (p) {
                return p.get('meta.customizationFormId');
            });

            return this.get('ajax').fetchList(_customizationForm.default, (0, _urls.buildFilterUrl)(SERVICES.customizationForms, {
                client: this.get('clientId'),
                id: formIds
            }) + '&include=elements');
        },
        fetchExtraProducts: function fetchExtraProducts() {
            var basketItems = (0, _collectors.flatMap)(this.get('basket.data.bookingGroups'), function (bg) {
                return bg.get('items');
            });
            var productsIds = (0, _collectors.uniqueMap)(basketItems, function (bi) {
                return bi.get('product');
            });

            return this.get('ajax').fetchList(_product.default, (0, _urls.buildFilterUrl)(SERVICES.products, {
                client: this.get('clientId'),
                event: this.get('projectId'),
                productGroup: 'Extras',
                with: productsIds
            }));
        },
        fetchDeliveryProducts: function fetchDeliveryProducts(products) {
            return this.get('ajax').fetchList(_product.default, (0, _urls.buildFilterUrl)(SERVICES.products, {
                client: this.get('clientId'),
                event: this.get('projectId'),
                productGroup: 'Deliveries',
                with: products.map(function (p) {
                    return p.get('id');
                })
            }));
        },
        buildBookingGroups: function buildBookingGroups(products, customizationForms, extras, deliveries) {
            var productsMap = (0, _collectors.hashMap)(products);

            return this.get('basket.data.sortedGroups').map(function (bg) {
                var mainItem = bg.get('mainItem');

                return _bookingGroup.default.create({
                    bookingGroup: bg,
                    product: productsMap[mainItem.get('product')],
                    customizationForms: customizationForms,
                    extras: extras,
                    deliveries: deliveries
                });
            });
        },

        // fetchAdditionalProducts() {
        //     return this.get('ajax').fetchList(Product, _url(SERVICES.products, {
        //         client:       this.get('clientId'),
        //         event:        this.get('projectId'),
        //         productGroup: 'Products'
        //     }))
        // },

        actions: {
            didTransition: function didTransition() {
                if (this.get('bubbleDidTransition') === false) {
                    this.set('bubbleDidTransition', true);
                    return false;
                }

                return true;
            },
            removeGroup: function removeGroup(group) {
                var _this3 = this;

                var ids = group.items.map(function (i) {
                    return i.id;
                });

                this.get('basket').removeItems(ids).then(function () {
                    return _this3.validateBasketState();
                });
            },
            saveCustomization: function saveCustomization(item, customization) {
                var basketItem = copy(item.get('basketItem'), true);
                basketItem.set('customization', customization);

                // return this.get('basket').updateItem(basketItem);
                return this.get('basket').updateItems([basketItem]);
            },
            nextStep: function nextStep() {
                var allCustomizationsFilled = true;

                // TODO: Eventually this should be done on the back-end.
                this.get('currentModel.bookingGroups').forEach(function (bg) {
                    bg.get('items').filter(function (i) {
                        return i.get('basketItem.customizationRequired');
                    }).forEach(function (i) {
                        var customization = i.get('basketItem.customization');

                        if (!isPresent(customization) || !customization.get('filled')) {
                            bg.set('customizationMissing', true);

                            allCustomizationsFilled = false;
                        }
                    });
                });

                if (allCustomizationsFilled) {
                    this.transitionTo('book.confirm-basket');
                } else {
                    window.scrollTo(0, 0);
                }
            }
        }
    });
});