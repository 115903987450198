define('customisation-web-public/pods/my-bookings/index/route', ['exports', 'customisation-web-public/config/environment', 'ember-sf-pageable/mixins/pageable-route', 'customisation-web-public/models/booking'], function (exports, _environment, _pageableRoute, _booking) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Route = Ember.Route;
    var inject = Ember.inject.service;
    var SERVICES = _environment.default.SERVICES;
    exports.default = Route.extend(_pageableRoute.default, {
        project: inject(),

        pageableModel: function pageableModel() {
            return {
                bookings: this.loadPage('bookings', _booking.default, SERVICES.bookings + '/bookings' + '?filter[productGroup:ne]=Vouchers' + ('&filter[partnerId]=' + this.get('project.data.id')))
            };
        }
    });
});