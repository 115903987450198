define('customisation-web-public/services/basket', ['exports', 'customisation-web-public/config/environment', 'customisation-web-public/models/basket', 'customisation-web-public/models/basket-item', 'customisation-web-public/models/basket-item-list', 'customisation-web-public/models/customer', 'customisation-web-public/models/product', 'customisation-web-public/models/delivery-region', 'customisation-web-public/models/data-protection-record', 'customisation-web-public/models/payment-info', 'customisation-web-public/models/booking', 'customisation-web-public/utils/collectors', 'customisation-web-public/utils/urls'], function (exports, _environment, _basket, _basketItem, _basketItemList, _customer, _product, _deliveryRegion, _dataProtectionRecord, _paymentInfo, _booking, _collectors, _urls) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Service = Ember.Service;
    var service = Ember.inject.service;
    var Evented = Ember.Evented;
    var copy = Ember.copy;
    var observer = Ember.observer;
    var isPresent = Ember.isPresent;
    var isEmpty = Ember.isEmpty;
    var typeOf = Ember.typeOf;
    var assign = Ember.assign;
    var RSVP = Ember.RSVP;
    var SERVICES = _environment.default.SERVICES;


    var BASKET_LIFETIME = 24 * 60 * 60; // 24 hours
    var DEFAULT_COUNTRY = 'GB';

    exports.default = Service.extend(Evented, {
        ajax: service(),
        session: service(),
        project: service(),
        ajaxproj: service(),

        data: null,
        eventId: null,
        event: null,
        currentBookingGroup: null,
        size: null,
        material: null,

        eventIdChanged: observer('eventId', function () {
            var eventId = this.get('eventId');

            if (eventId) {
                localStorage.setItem('eventId', eventId);
                this.changeEvent(eventId);
            } else {
                localStorage.removeItem('eventId');
            }
        }),

        sizeChanged: observer('size', function () {
            var size = this.get('size');
            if (size) {
                localStorage.setItem('size', size);
            } else {
                localStorage.removeItem('size');
            }
        }),

        materialChanged: observer('material', function () {
            var material = this.get('material');
            if (material) {
                localStorage.setItem('material', material);
            } else {
                localStorage.removeItem('material');
            }
        }),

        init: function init() {
            this._super.apply(this, arguments);

            return this.setup();
        },
        setup: function setup() {
            var _this = this;

            var forceReload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

            this.flushBasketIfExpired();

            // Make sure normally it's executed only once on a page.
            if (!forceReload && this.get('_setupPromise')) {
                return this.get('_setupPromise');
            }

            /* TODO: Store basket details in localStorage as object?
            localStorage.basket = {
                "id":      "927ef454-0bd8-4634-923b-5cbc2c849024",
                "eventId": "0b62de9a-8921-4460-9925-06749c1861dc",
                ...
            }
            */
            /*
            const setupPromise = new RSVP.Promise(resolve => {
                const eventId  = localStorage.getItem('eventId');
                const basketId = localStorage.getItem('basketId');
                 this.set('eventId', eventId);
                 this.changeEvent(eventId)
                    .then(() => {
                        this.loadBasketData(basketId).then(() => resolve());
                    })
                    .catch(() => {
                        this.set('eventId', null);
                         this.loadBasketData(basketId).then(() => resolve());
                    });
            });
            */
            var eventId = localStorage.getItem('eventId');
            var basketId = localStorage.getItem('basketId');

            this.set('size', localStorage.getItem('size'));
            this.set('material', localStorage.getItem('material'));

            var setupPromise = this.changeEvent(eventId).then(function () {
                return _this.loadBasketData(basketId);
            }).catch(function () {
                _this.set('eventId', null);

                return _this.loadBasketData(basketId);
            });

            this.set('_setupPromise', setupPromise);

            return setupPromise;
        },
        refresh: function refresh() {
            var _this2 = this;

            return this.setup(true).then(function () {
                return _this2.trigger('basketRefreshed');
            });
        },
        changeEvent: function changeEvent() /*eventId*/{
            /*
            if (isPresent(eventId) && eventId !== 'null') {
                return this.get('ajax').fetchModel(Activity, `${SERVICES.activities}/${eventId}`)
                    .then(event => {
                        localStorage.setItem('eventId', event.get('id'));
                        this.set('event', event);
                         return this.get('ajax').fetchModel(Venue, `${SERVICES.venues}/venues/${event.venue.id}`)
                            .then(venue => event.set('venue', venue));
                    });
            }
            */

            return RSVP.resolve();
        },
        loadBasketData: function loadBasketData(basketId) {
            var _this3 = this;

            var projectData = this.get('project.data');

            return new RSVP.Promise(function (resolve) {
                if (isPresent(basketId) && basketId !== 'null') {
                    _this3.get('ajax').fetchModel(_basket.default, SERVICES.baskets + '/baskets/' + basketId).then(function (basket) {
                        var oldCustomer = _this3.get('data.customer');

                        basket.set('client', projectData.get('client'));
                        basket.set('partner', projectData);
                        _this3.set('data', basket);
                        localStorage.setItem('basketId', basket.get('id'));

                        if (_this3.get('data.itemsQty') === 0) {
                            _this3.trigger('basketEmpty');
                        }

                        if (_this3.get('session.isAuthenticated')) {
                            _this3.get('ajax').fetchModel(_customer.default, SERVICES.customers + '/me').then(function (customer) {
                                _this3.set('data.customer', customer);
                                resolve(basket);
                            }).catch(function (error) {
                                _this3.set('data.customer', _this3.get('session.currentCustomer'));
                                return error;
                            });
                        } else {
                            if (oldCustomer) {
                                _this3.set('data.customer', oldCustomer);
                            } else {
                                var customer = _customer.default.create();
                                customer.set('mainAddress.countryId', DEFAULT_COUNTRY);
                                _this3.set('data.customer', customer);
                            }
                            resolve(basket);
                        }
                    }).catch(function (error) {
                        resolve(_this3._initBlankBasket());
                        return error;
                    });
                } else {
                    resolve(_this3._initBlankBasket());
                }
            });
        },
        _initBlankBasket: function _initBlankBasket() {
            var projectData = this.get('project.data');
            var blankBasket = _basket.default.create({ client: projectData.get('client'), partner: projectData });

            localStorage.setItem('basketId', null);
            this.set('data', blankBasket);
            this.trigger('basketNotPersisted');

            return blankBasket;
        },
        persist: function persist(currency) {
            var _this4 = this;

            this.set('data.currency', currency);

            // The basket is already persisted.
            if (isPresent(this.get('data.id'))) {
                return RSVP.resolve();
            }

            return this.get('ajax').post(SERVICES.baskets + '/baskets', _basket.default.stringify(this.get('data')), _basket.default).then(function (basket) {
                localStorage.setItem('basketId', basket.get('id'));

                return _this4.refresh();
            });
        },
        flushBasket: function flushBasket() {
            localStorage.removeItem('basketExp');
            localStorage.removeItem('basketId');
            localStorage.removeItem('eventId');
            this.set('currentBookingGroup', null);
            this.refresh();
        },
        flushBasketIfExpired: function flushBasketIfExpired() {
            var exp = parseInt(localStorage.getItem('basketExp'));
            var now = Date.now() / 1000 | 0;

            if (exp && exp < now) {
                // If expired - flush the basket.

                this.flushBasket();
                this.trigger('basketExpired');
            } else {
                // Otherwise - extend basket lifetime.

                var eventId = localStorage.getItem('eventId');
                var basketId = localStorage.getItem('basketId');

                if (isPresent(eventId) || isPresent(basketId)) {
                    localStorage.setItem('basketExp', now + BASKET_LIFETIME);
                }
            }
        },
        empty: function empty() {
            var _this5 = this;

            this.get('ajax').post(SERVICES.baskets + '/baskets/' + this.get('data.id') + '/clear').then(function () {
                _this5.trigger('basketCleared');

                _this5.set('data.bookingGroups', []);
                _this5.set('data.total', null);
                _this5.flushBasket();
            });
        },
        flushItems: function flushItems(itemList) {
            var _this6 = this;

            var itemsData = _basketItemList.default.stringify(itemList);

            return this.get('ajax').post(SERVICES.baskets + '/baskets/' + this.get('data.id') + '/products', itemsData, _basket.default).then(function (basket) {
                basket.set('customer', _this6.get('data.customer'));
                _this6.set('data', basket);

                localStorage.setItem('basketId', basket.get('id'));
            });
        },

        // addItem(product) {
        //     this.get('data.items').pushObject(product);
        // },
        updateItems: function updateItems(items) {
            var _this7 = this;

            var itemsData = _basketItemList.default.stringify(_basketItemList.default.create({
                items: items.map(function (i) {
                    return JSON.parse(_basketItem.default.stringify(i));
                })
            }));

            return this.get('ajax').patch(SERVICES.baskets + '/baskets/' + this.get('data.id') + '/products', itemsData, _basket.default).then(function () {
                return _this7.refresh();
            });
        },

        // updateItem(item) {
        //     const itemData = BasketItem.stringify(item);
        //
        //     return this.get('ajax').patch(`${SERVICES.baskets}/baskets/${this.get('data.id')}/products/${item.get('id')}`, itemData, Basket)
        //         .then(() => this.refresh());
        // },
        replaceItem: function replaceItem(oldItem, newItem) {
            var _this8 = this;

            var list = _basketItemList.default.create({
                items: [JSON.parse(_basketItem.default.stringify(newItem))]
            });
            var itemsData = _basketItemList.default.stringify(list);

            return this.get('ajax').post(SERVICES.baskets + '/baskets/' + this.get('data.id') + '/products', itemsData, _basket.default).then(function () {
                _this8.set('currentBookingGroup', newItem.bookingGroup);

                return _this8.removeItem(oldItem);
            });
        },
        replaceItems: function replaceItems(oldItems, newItems) {
            var _this9 = this;

            var list = _basketItemList.default.create({
                items: newItems.map(function (bi) {
                    return JSON.parse(_basketItem.default.stringify(bi));
                })
            });
            var itemsData = _basketItemList.default.stringify(list);

            return this.get('ajax').post(SERVICES.baskets + '/baskets/' + this.get('data.id') + '/products', itemsData, _basket.default).then(function () {
                return _this9.removeItems(oldItems.map(function (bi) {
                    return bi.get('id');
                }));
            });
        },
        removeItem: function removeItem(item) {
            var _this10 = this;

            return this.get('ajax').remove(SERVICES.baskets + '/baskets/' + this.get('data.id') + '/products/' + item.id).then(function () {
                return _this10.refresh();
            });
        },
        removeItems: function removeItems(ids) {
            var _this11 = this;

            var itemsData = _basketItemList.default.stringify(_basketItemList.default.create({ items: ids }));

            return this.get('ajax').remove(SERVICES.baskets + '/baskets/' + this.get('data.id') + '/products', itemsData, _basket.default).then(function () {
                return _this11.refresh();
            });
        },


        /*
        // EXPERIMENTAL
        _addProducts({bookingGroup, products, additionalMeta, unique = false, grouped = true}) {
            // params = {
            //     products:     [],
            //     meta:         {},
            //     bookingGroup: {}, // {} | ''
            //     grouped:      true
            // }
             if (products && products.length > 0) {
                if (unique) {
                    // Make sure the same product may not be added into the basket multiple times.
                    for (let product of products) {
                        if (this.get('data').containsProduct(product)) {
                            throw new EmberError(`DUPLICATE_PRODUCT: The product may be added to the basket only once [${product.get('id')}]`);
                        }
                    }
                }
                 let bookingGroupInfo;
                const list = BasketItemList.create({items: []});
                 if (grouped) {
                    bookingGroupInfo = bookingGroup || this.getNewBookingGroupInfo();
                }
                 for (let product of products) {
                    if (product.get('qty') > 0) {
                        if (!grouped && !bookingGroup) {
                            bookingGroupInfo = this.getNewBookingGroupInfo(product);
                            console.log(bookingGroupInfo.meta.index);
                        }
                         const meta = assign({}, bookingGroupInfo.meta, product.get('meta'), additionalMeta, {
                            productType:  product.productType,
                            iconUrl:      product.iconUrl
                        });
                         const basketItem = BasketItem.create({
                            bookingGroup:   bookingGroupInfo.bookingGroup,
                            product:        product,
                            productType:    product.productType,
                            productGroup:   product.productGroup,
                            quantity:       product.qty,
                            deliveryOption: product.deliveryOption,
                            meta:           meta
                        });
                         list.get('items').pushObject(JSON.parse(BasketItem.stringify(basketItem)));
                    }
                }
                 return this.flushItems(list)
                    .then(() => this.set('currentBookingGroup', bookingGroupInfo.bookingGroup));
            }
             return RSVP.resolve();
        },
        */
        createBasketItem: function createBasketItem(bookingGroup, product, quantity, additionalMeta) {
            if (typeOf(bookingGroup) === 'string') {
                bookingGroup = this.getBookingGroupAsObject(bookingGroup);
            }

            var meta = assign({}, bookingGroup.meta, product.get('meta'), additionalMeta, {
                productType: product.productType,
                iconUrl: product.iconUrl
            });

            return _basketItem.default.create({
                bookingGroup: bookingGroup.bookingGroup,
                product: product,
                productType: product.productType,
                productGroup: product.productGroup,
                quantity: quantity,
                deliveryOption: product.deliveryOption,
                customization: product.customization,
                meta: meta
            });
        },
        addProducts: function addProducts(products, additionalMeta) {
            var _this12 = this;

            if (products.length > 0) {
                // Just in case, make sure the same product may not be added into the basket multiple times.
                // for (let product of products) {
                //     if (this.get('data').containsProduct(product)) {
                //         throw new EmberError(`DUPLICATE_PRODUCT: The product may be added to the basket only once [${product.get('id')}]`);
                //     }
                // }

                var currentGroupInfo = void 0;
                var list = _basketItemList.default.create({ items: [] });

                var _iteratorNormalCompletion = true;
                var _didIteratorError = false;
                var _iteratorError = undefined;

                try {
                    for (var _iterator = products[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                        var product = _step.value;

                        if (product.get('qty') > 0) {
                            var _currentGroupInfo = this.getNewBookingGroupInfo(product);
                            var basketItem = this.createBasketItem(_currentGroupInfo, product, product.qty, additionalMeta);

                            list.get('items').pushObject(JSON.parse(_basketItem.default.stringify(basketItem)));
                        }
                    }
                } catch (err) {
                    _didIteratorError = true;
                    _iteratorError = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion && _iterator.return) {
                            _iterator.return();
                        }
                    } finally {
                        if (_didIteratorError) {
                            throw _iteratorError;
                        }
                    }
                }

                return this.flushItems(list).then(function () {
                    if (currentGroupInfo) {
                        _this12.set('currentBookingGroup', currentGroupInfo.bookingGroup);
                    }
                });
            }

            return this.flushItems(_basketItemList.default.create({ items: [] }));
        },
        replaceProduct: function replaceProduct(item, product) {
            var newItem = copy(item, true);

            newItem.setProperties({
                id: null,
                product: product,
                productType: product.productType,
                productGroup: product.productGroup,
                deliveryOption: product.deliveryOption
            });
            newItem.set('meta', assign({}, newItem.get('meta'), {
                productType: product.productType,
                iconUrl: product.iconUrl
            }));

            return this.replaceItem(item, newItem);
        },
        addExtraProducts: function addExtraProducts(products, bookingGroup, additionalMeta) {
            var _this13 = this;

            if (products.length > 0) {
                bookingGroup = this.getBookingGroupAsObject(bookingGroup);
                var list = _basketItemList.default.create({ items: [] });

                var _iteratorNormalCompletion2 = true;
                var _didIteratorError2 = false;
                var _iteratorError2 = undefined;

                try {
                    for (var _iterator2 = products[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                        var product = _step2.value;

                        if (product.get('qty') > 0) {
                            var basketItem = this.createBasketItem(bookingGroup, product, product.qty, additionalMeta);

                            list.get('items').pushObject(JSON.parse(_basketItem.default.stringify(basketItem)));
                        }
                    }
                } catch (err) {
                    _didIteratorError2 = true;
                    _iteratorError2 = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion2 && _iterator2.return) {
                            _iterator2.return();
                        }
                    } finally {
                        if (_didIteratorError2) {
                            throw _iteratorError2;
                        }
                    }
                }

                return this.flushItems(list).then(function () {
                    _this13.set('currentBookingGroup', bookingGroup);
                });
            }

            return this.flushItems(_basketItemList.default.create({ items: [] }));
        },
        replaceDeliveryProducts: function replaceDeliveryProducts(productToDeliveryMap) {
            var productIds = Object.keys(productToDeliveryMap);

            if (productIds.length > 0) {
                var items = (0, _collectors.flatMap)(this.get('data.bookingGroups'), function (bg) {
                    return bg.get('items');
                });
                var oldItems = [];
                var newItems = [];

                var _iteratorNormalCompletion3 = true;
                var _didIteratorError3 = false;
                var _iteratorError3 = undefined;

                try {
                    for (var _iterator3 = items[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                        var item = _step3.value;

                        var deliveryFor = item.get('meta.deliveryFor');

                        if (isPresent(deliveryFor)) {
                            oldItems.pushObject(item);
                        }
                    }
                } catch (err) {
                    _didIteratorError3 = true;
                    _iteratorError3 = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion3 && _iterator3.return) {
                            _iterator3.return();
                        }
                    } finally {
                        if (_didIteratorError3) {
                            throw _iteratorError3;
                        }
                    }
                }

                var _iteratorNormalCompletion4 = true;
                var _didIteratorError4 = false;
                var _iteratorError4 = undefined;

                try {
                    for (var _iterator4 = items[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
                        var _item = _step4.value;

                        var bookingGroup = this.getBookingGroupAsObject(_item.get('bookingGroup'));
                        var delivery = productToDeliveryMap[_item.get('product')];

                        if (delivery) {
                            var meta = { deliveryFor: _item.get('product') };
                            var basketItem = this.createBasketItem(bookingGroup, delivery, _item.get('quantity'), meta);

                            newItems.pushObject(basketItem);
                        }
                    }
                } catch (err) {
                    _didIteratorError4 = true;
                    _iteratorError4 = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion4 && _iterator4.return) {
                            _iterator4.return();
                        }
                    } finally {
                        if (_didIteratorError4) {
                            throw _iteratorError4;
                        }
                    }
                }

                return this.replaceItems(oldItems, newItems);
            }

            return RSVP.resolve(this.get('data'));
        },
        getNewBookingGroupInfo: function getNewBookingGroupInfo(product) {
            var name = product ? product.get('name') : '';

            var currentIdx = 1;
            if (isPresent(this.get('data.bookingGroups'))) {
                this.get('data.bookingGroups').forEach(function (group) {
                    var parsedName = JSON.parse(group.get('bookingGroup'));
                    if (parsedName.name === name) {
                        currentIdx++;
                    }
                });
            }

            var bookingGroupData = {
                name: name,
                subname: product ? product.get('subname') : '',
                description: product ? product.get('description') : '',
                index: currentIdx,
                iter_index: isPresent(this.get('data.bookingGroups')) ? this.get('data.bookingGroups').length + 1 : 1
            };

            return {
                bookingGroup: JSON.stringify(bookingGroupData),
                meta: bookingGroupData
            };
        },
        getBookingGroupAsObject: function getBookingGroupAsObject(bookingGroupStr) {
            return {
                bookingGroup: bookingGroupStr,
                meta: JSON.parse(bookingGroupStr)
            };
        },
        acceptTerms: function acceptTerms(termsAccepted) {
            return this.get('ajax').post(SERVICES.baskets + '/baskets/' + this.get('data.id') + '/termsAccepted/' + termsAccepted);
        },
        addPromotion: function addPromotion(code) {
            var _this14 = this;

            return new RSVP.Promise(function (resolve, reject) {
                if (isPresent(code) && code != 'null') {
                    _this14.get('ajax').post(SERVICES.baskets + '/baskets/' + _this14.get('data.id') + '/promotion/' + code, null, _basket.default).then(function (basket) {
                        _this14.set('data', basket);
                        resolve();
                    }, function (errors) {
                        return reject(errors);
                    });
                } else {
                    reject({ code: 'may not be empty' });
                }
            });
        },
        updateCustomerOptin: function updateCustomerOptin(customerId, optin) {
            var _this15 = this;

            var customer = _customer.default.create({
                id: customerId,
                optin: optin
            });

            return this.get('ajax').patch(SERVICES.customers + '/customers/' + customer.id, _customer.default.stringify(customer), _customer.default).then(function (customer) {
                var dataProtection = _dataProtectionRecord.default.stringify(_dataProtectionRecord.default.create({
                    clientId: _this15.get('project.data.client.id'),
                    customer: customer,
                    type: 'OPTIN',
                    source: 'PUBLIC_BOOKER',
                    value: customer.optin,
                    changeRequesterType: 'CUSTOMER'
                }));

                return _this15.get('ajax').post(SERVICES.customers + '/data-protection', dataProtection);
            });
        },
        attachCustomer: function attachCustomer(customer) {
            var _this16 = this;

            return this.get('ajax').post(SERVICES.baskets + '/baskets/' + this.get('data.id') + '/customer/' + customer.get('id')).then(function () {
                return _this16.refresh();
            });
        },
        checkout: function checkout(paymentInfo) {
            var paymentData = _paymentInfo.default.stringify(paymentInfo);

            return this.get('ajax').post(SERVICES.baskets + '/baskets/' + this.get('data.id') + '/checkout?newVersion=true', paymentData, _booking.default).then(function (booking) {
                // this.flushBasket(); // TODO:?
                return booking;
            });
        },
        refreshDeliveryProducts: function refreshDeliveryProducts() {
            var _this17 = this;

            var country = this.get('data.customer.mainAddress.countryId');
            if (isEmpty(country)) {
                country = DEFAULT_COUNTRY;
            }

            return this.fetchDeliveryRegions(country).then(function (regions) {
                var basketItems = (0, _collectors.flatMap)(_this17.get('data.bookingGroups'), function (bg) {
                    return bg.get('items');
                });
                var productIds = (0, _collectors.uniqueMap)(basketItems, function (bi) {
                    return bi.get('product');
                });

                return _this17.fetchDeliveryProducts(productIds, regions).then(function (deliveries) {
                    var deliveriesMap = (0, _collectors.hashMap)(deliveries, function (d) {
                        return d.get('availableWith').split(',');
                    });
                    var productToDeliveryMap = (0, _collectors.hashMap)(basketItems, function (bi) {
                        return bi.get('product');
                    }, function (bi) {
                        return deliveriesMap[bi.get('product')];
                    });

                    return _this17.replaceDeliveryProducts(productToDeliveryMap);
                });
            });
        },
        fetchDeliveryRegions: function fetchDeliveryRegions(countryId) {
            var clientId = this.get('project.data.client.id');

            return this.get('ajaxproj').fetchList(_deliveryRegion.default, (0, _urls.buildFilterUrl)(SERVICES.deliveryRegions, {
                client: clientId,
                country: countryId
            }));
        },
        fetchDeliveryProducts: function fetchDeliveryProducts(productIds, regions) {
            var clientId = this.get('project.data.client.id');

            return this.get('ajax').fetchList(_product.default, (0, _urls.buildFilterUrl)(SERVICES.products, {
                client: clientId,
                event: regions.map(function (r) {
                    return r.get('id');
                }),
                productGroup: 'Deliveries',
                with: productIds
            }));
        }
    }

    // updateInfo(preferences) {
    //     if (isPresent(preferences)) {
    //         this.set('data.info', preferences);
    //     }
    //
    //     const basketData = Basket.stringify(this.get('data'));
    //
    //     return this.get('ajax').post(`${SERVICES.baskets}/baskets/${this.get('data.id')}/info`, basketData, Basket);
    // }
    );
});