define('customisation-web-public/pods/book/customize-product/customization-preview/component', ['exports', 'customisation-web-public/utils/collectors', 'customisation-web-public/config/environment'], function (exports, _collectors, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component;
    var computed = Ember.computed;
    var isEmpty = Ember.isEmpty;
    var rootURL = _environment.default.rootURL;


    /*
     * Replaces placeholders in the template with values.
     * Placeholder examples: {{someName}}, {{someName[0]}}
     */
    var _renderTemplate = function _renderTemplate(template, model) {
        var result = template;

        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
            for (var _iterator = Object.keys(model)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                var key = _step.value;

                var safeKey = key.replace(/\[/g, '\\[');
                result = result.replace(new RegExp('{{' + safeKey + '}}', 'g'), model[key]);
            }
        } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
        } finally {
            try {
                if (!_iteratorNormalCompletion && _iterator.return) {
                    _iterator.return();
                }
            } finally {
                if (_didIteratorError) {
                    throw _iteratorError;
                }
            }
        }

        return result;
    };

    /*
     * Replaces all placeholders with empty strings.
     */
    var _emptyTemplate = function _emptyTemplate(template) {
        return template.replace(/\{\{[^}]*}}/g, '');
    };

    exports.default = Component.extend({
        tagName: 'div',
        classNames: ['customization-preview'],

        elements: computed('customization.formDetails.@each.answer', function () {
            if (!this.get('customization.formDetails')) {
                return [];
            }

            return (0, _collectors.flatMap)(this.get('customization.formDetails'), function (d) {
                return d.get('answer');
            });
        }),
        previewTemplate: computed('template', 'customization.formPreviewTemplate', function () {
            var customizationTemplate = this.get('customization.formPreviewTemplate');

            if (!isEmpty(customizationTemplate)) {
                return customizationTemplate;
            }

            return this.get('template');
        }),
        renderedTemplate: computed('previewTemplate', 'elements.@each.value', 'customization.material', function () {
            var template = this.get('previewTemplate');

            if (isEmpty(template)) {
                return '<div class="no-template">NO PREVIEW</div>';
            }

            //template = template.replace("{{background}}", this.get('customization.background'));
            template = template.replace("{{background}}", rootURL + 'assets/img/plaque-background.jpg');
            template = template.replace(/material_bg/g, "material_bg_" + this.get('customization.material'));

            if (isEmpty(this.get('elements'))) {
                return _emptyTemplate(template);
            }

            var model = this.get('elements').reduce(function (accum, item, idx) {
                accum['elements[' + idx + ']'] = item.value;
                return accum;
            }, {});

            return _renderTemplate(template, model);
        })
    });
});