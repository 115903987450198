define('customisation-web-public/authenticators/custom-jwt', ['exports', 'ember-simple-auth-token/authenticators/jwt', 'customisation-web-public/config/environment', 'customisation-web-public/models/customer'], function (exports, _jwt, _environment, _customer) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _defineProperty(obj, key, value) {
        if (key in obj) {
            Object.defineProperty(obj, key, {
                value: value,
                enumerable: true,
                configurable: true,
                writable: true
            });
        } else {
            obj[key] = value;
        }

        return obj;
    }

    var $ = Ember.$;
    var get = Ember.get;
    var inject = Ember.inject.service;
    var typeOf = Ember.typeOf;
    var run = Ember.run;
    var RSVP = Ember.RSVP;
    var SERVICES = _environment.default.SERVICES;


    var CONTENT_TYPE_JSON_API = 'application/vnd.api+json; charset=utf-8';

    exports.default = _jwt.default.extend({
        session: inject(),
        project: inject(),

        __setupSession: function __setupSession(resolve, reject, tokenData) {
            var _this = this;

            $.ajax(SERVICES.customers + '/me', {
                contentType: CONTENT_TYPE_JSON_API,
                headers: { 'Authorization': 'Bearer ' + tokenData.token }
            }).then(function (customerData) {
                run(function () {
                    _this.get('session').setup(_customer.default.fromJson(customerData.data));

                    resolve(tokenData);
                });
            }, function () {
                run(function () {
                    return reject();
                });
            });
        },
        restore: function restore(sessionData) {
            var _this2 = this;

            return new RSVP.Promise(function (resolve, reject) {
                // Call super JwtAuthenticator.restore() first, which schedules token refresh.
                _this2._super(sessionData).then(function (jwtTokenData) {
                    // Make a call to /customers/me here just to check the token is valid
                    // and trigger redirect to login page before we get to any protected page.

                    run(function () {
                        return _this2.__setupSession(resolve, reject, jwtTokenData);
                    });
                }).catch(function (error) {
                    run(function () {
                        return reject(error);
                    });
                });
            });
        },
        authenticate: function authenticate() /*credentials, headers*/{
            var _this3 = this,
                _arguments = arguments;

            return new RSVP.Promise(function (resolve, reject) {
                _this3._super.apply(_this3, _arguments).then(function (response) {
                    run(function () {
                        return _this3.__setupSession(resolve, reject, response);
                    });
                }).catch(function (error) {
                    run(function () {
                        if (typeOf(error) === 'object') {
                            // Only one global error is expected.
                            reject(error.errors[0].title);
                        } else {
                            reject(error);
                        }
                    });
                });
            });
        },
        handleAuthResponse: function handleAuthResponse(response) {
            response[this.tokenPropertyName] = response.data.id;

            return this._super(response);
        },
        getAuthenticateData: function getAuthenticateData(credentials) {
            var _attributes;

            return {
                data: {
                    type: 'credentials',
                    attributes: (_attributes = {}, _defineProperty(_attributes, this.identificationField, credentials.username), _defineProperty(_attributes, this.passwordField, credentials.password), _defineProperty(_attributes, 'clientId', this.get('project.data.client.id')), _attributes)
                }
            };
        },
        makeRefreshData: function makeRefreshData() /*token*/{
            var refreshData = this._super.apply(this, arguments);

            return {
                data: {
                    type: 'tokens',
                    id: get(refreshData, this.tokenPropertyName)
                }
            };
        },
        getResponseData: function getResponseData(response) {
            return { token: get(response, this.tokenPropertyName) };
        },
        makeRequest: function makeRequest(url, data, headers) {
            return $.ajax({
                url: url,
                method: 'POST',
                data: JSON.stringify(data),
                dataType: 'json',
                contentType: CONTENT_TYPE_JSON_API,
                headers: this.headers,

                beforeSend: function beforeSend(xhr) {
                    if (headers) {
                        Object.keys(headers).forEach(function (key) {
                            xhr.setRequestHeader(key, headers[key]);
                        });
                    }
                }
            });
        }
    });
});