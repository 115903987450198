define('customisation-web-public/pods/book-addition/select-products/booking-group', ['exports', 'customisation-web-public/pods/book-addition/select-products/booking-item', 'customisation-web-public/utils/collectors'], function (exports, _bookingItem, _collectors) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var EmberObject = Ember.Object;
    var computed = Ember.computed;
    var isEmpty = Ember.isEmpty;


    var BookingGroup = EmberObject.extend({
        bookingGroup: null,
        extras: null,
        deliveries: null,

        availableProducts: computed('bookingGroup.items.@each', 'extras.@each', function () {
            var ret = [];
            var existingProducts = [];
            var mainProduct = this.get('bookingGroup.mainItem.product');
            var extrasMap = (0, _collectors.hashMap)(this.get('extras'));

            // Add SOFT_PENCIL items already present in the booking group.
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = this.get('bookingGroup.items')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var item = _step.value;

                    if (!item.get('isExtra') || item.get('isDelivery') || item.get('status') !== 'SOFT_PENCIL') {
                        continue;
                    }

                    var extra = extrasMap[item.get('product')];
                    var delivery = this.deliveryProductFor(extra);

                    ret.pushObject(_bookingItem.default.create({
                        bookingItem: item,
                        deliveryItem: this.get('bookingGroup.items').findBy('product', delivery.get('id')),
                        product: extra,
                        quantity: item.get('quantity'),
                        deliveryProduct: delivery
                    }));

                    existingProducts.pushObject(extra.get('id'));
                }

                // Add items/products that are available for this booking group.
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }

            var availableExtras = this.get('extras').filter(function (e) {
                return isEmpty(e.get('availableWith')) || e.get('availableWith').includes(mainProduct);
            }).filter(function (e) {
                return !existingProducts.includes(e.get('id'));
            });
            var _iteratorNormalCompletion2 = true;
            var _didIteratorError2 = false;
            var _iteratorError2 = undefined;

            try {
                for (var _iterator2 = availableExtras[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                    var extra = _step2.value;

                    ret.pushObject(_bookingItem.default.create({
                        product: extra,
                        deliveryProduct: this.deliveryProductFor(extra)
                    }));
                }

                // Sort the items by price.
            } catch (err) {
                _didIteratorError2 = true;
                _iteratorError2 = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion2 && _iterator2.return) {
                        _iterator2.return();
                    }
                } finally {
                    if (_didIteratorError2) {
                        throw _iteratorError2;
                    }
                }
            }

            return ret.sort(function (a, b) {
                return b.get('product.price') - a.get('product.price');
            });
        }),

        deliveryProductFor: function deliveryProductFor(product) {
            return this.get('deliveries').find(function (d) {
                return !isEmpty(d.get('availableWith')) && d.get('availableWith').includes(product.get('id'));
            });
        }
    });

    exports.default = BookingGroup;
});