define('customisation-web-public/routes/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Route = Ember.Route;
    exports.default = Route.extend({
        redirect: function redirect() {
            this.transitionTo('book.select-products');
        }
    });
});