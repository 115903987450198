define('customisation-web-public/services/ajaxproj', ['exports', 'customisation-web-public/services/ajax'], function (exports, _ajax) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service;
    exports.default = _ajax.default.extend({
        project: service(),

        _appendProjectFilter: function _appendProjectFilter(url) {
            return this._appendMapParams(url, 'filter', { project: this.get('project.data.id') });
        },
        rawRequest: function rawRequest(url, options) {
            return this._super(this._appendProjectFilter(url), options);
        },
        downloadAttachment: function downloadAttachment(url, defaultFilename, type) {
            return this._super(this._appendProjectFilter(url), defaultFilename, type);
        }
    });
});