define('customisation-web-public/models/product', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model'], function (exports, _jsonapiModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var EmberObject = Ember.Object;
    var computed = Ember.computed;
    var isEmpty = Ember.isEmpty;


    var Product = EmberObject.extend(Ember.Copyable, {
        id: null,
        productType: null,
        productGroup: null,
        originId: null,
        name: null,
        subname: null,
        description: null,
        iconUrl: null,
        price: null,
        currency: null,
        // vatRate:         null,
        inventory: null,
        available: null,
        meta: null,
        deliveryOptions: null,

        customizable: computed('meta.customizationFormId', function () {
            return !isEmpty(this.get('meta.customizationFormId'));
        }),

        size: computed('name', function () {
            return this.get('name').toLowerCase().indexOf("large") === -1 ? "regular" : "large";
        })
    });

    Product.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'products'
        }
    });

    Product.reopen({
        copy: function copy() {
            return Product.create(this.getProperties(Object.keys(this)));
        }
    });

    exports.default = Product;
});