define('customisation-web-public/mixins/view-booking-route', ['exports', 'customisation-web-public/config/environment', 'customisation-web-public/models/booking', 'customisation-web-public/models/customized-product', 'customisation-web-public/utils/collectors'], function (exports, _environment, _booking, _customizedProduct, _collectors) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Mixin = Ember.Mixin;
    var service = Ember.inject.service;
    var set = Ember.set;
    var on = Ember.on;
    var copy = Ember.copy;
    var run = Ember.run;
    var RSVP = Ember.RSVP;
    var SERVICES = _environment.default.SERVICES;
    exports.default = Mixin.create({
        ajax: service(),
        ajaxproj: service(),

        _viewBookingRouteMixin_activate: on('activate', function () {
            this.set('bubbleDidTransition', true);
        }),

        model: function model(_ref) {
            var booking_id = _ref.booking_id;

            return this.fetchBooking(booking_id, !!this.get('confirmation'));
        },
        fetchBooking: function fetchBooking(id) {
            var _this = this;

            var waitIfPending = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

            var bookingPromise = this.get('ajax').fetchModel(_booking.default, SERVICES.bookings + '/bookings/' + id + '?include=customers');

            return bookingPromise.then(function (booking) {
                if (waitIfPending && booking.get('status') === 'PENDING') {
                    run.later(function () {
                        _this._refreshWithoutScroll();
                    }, 1500);

                    return { pending: true };
                }

                return _this._getExtendedBooking(booking);
            });
        },
        _getExtendedBooking: function _getExtendedBooking(booking) {
            var _this2 = this;

            return this.get('ajaxproj').fetchList(_customizedProduct.default, SERVICES.customizedProducts + '?filter[booking]=' + booking.id).then(function (products) {
                var productsMap = (0, _collectors.hashMap)(products, function (p) {
                    return p.get('bookingItemId');
                });

                var certAvailabilityMap = {};

                var _iteratorNormalCompletion = true;
                var _didIteratorError = false;
                var _iteratorError = undefined;

                try {
                    for (var _iterator = booking.get('groupItems')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                        var item = _step.value;

                        var customizedProduct = productsMap[item.id];
                        item.set('customizedProduct', customizedProduct);
                        certAvailabilityMap[customizedProduct.id] = _this2.get('ajax').rawRequest(SERVICES.certificates + '/' + customizedProduct.id + '/check', { method: 'GET', dataType: 'text' }).then(function () {
                            return true;
                        }).catch(function () {
                            return false;
                        });
                    }
                } catch (err) {
                    _didIteratorError = true;
                    _iteratorError = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion && _iterator.return) {
                            _iterator.return();
                        }
                    } finally {
                        if (_didIteratorError) {
                            throw _iteratorError;
                        }
                    }
                }

                return RSVP.hash(certAvailabilityMap).then(function (availability) {
                    var _iteratorNormalCompletion2 = true;
                    var _didIteratorError2 = false;
                    var _iteratorError2 = undefined;

                    try {
                        for (var _iterator2 = booking.get('groupItems')[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                            var _item = _step2.value;

                            if (_item.get('customizedProduct')) {
                                _item.set('customizedProduct.isCertificateConfigured', availability[_item.get('customizedProduct.id')]);
                            }
                        }
                    } catch (err) {
                        _didIteratorError2 = true;
                        _iteratorError2 = err;
                    } finally {
                        try {
                            if (!_iteratorNormalCompletion2 && _iterator2.return) {
                                _iterator2.return();
                            }
                        } finally {
                            if (_didIteratorError2) {
                                throw _iteratorError2;
                            }
                        }
                    }

                    return booking;
                });
            });
        },
        _refreshWithoutScroll: function _refreshWithoutScroll() {
            this.refresh();
            this.set('bubbleDidTransition', false);
        },


        actions: {
            didTransition: function didTransition() {
                if (this.get('bubbleDidTransition') === false) {
                    this.set('bubbleDidTransition', true);
                    return false;
                }

                return true;
            },
            downloadCertificate: function downloadCertificate(customizedProduct) {
                var certificateUrl = SERVICES.certificates + '/' + customizedProduct.id;
                this.get('ajax').downloadAttachment(certificateUrl, 'certificate.pdf', 'application/pdf');
            },
            saveCustomization: function saveCustomization(item, customization) {
                var _this3 = this;

                var booking = this.get('currentModel');
                var origItem = booking.get('groupItems').findBy('id', item.get('id'));
                var customizedProduct = origItem.customizedProduct;

                if (origItem && customizedProduct) {
                    customizedProduct = copy(customizedProduct, true);
                    set(customizedProduct, 'customization', customization);

                    var productData = _customizedProduct.default.stringify(customizedProduct);
                    return this.get('ajax').patch(SERVICES.customizedProducts + '/' + customizedProduct.id, productData, _customizedProduct.default).then(function () {
                        return _this3._refreshWithoutScroll();
                    });
                }

                return RSVP.reject({
                    customization: {
                        errors: 'Product is not customizable'
                    }
                });
            }
        }
    });
});