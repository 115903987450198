define('customisation-web-public/router', ['exports', 'customisation-web-public/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberRouter = Ember.Router;
  var isBlank = Ember.isBlank;


  var Router = EmberRouter.extend({
    location: _environment.default.locationType
  });

  Router.map(function () {
    this.route('404', { path: '/*wildcard' });

    this.route('login');
    this.route('forgotten-password');
    this.route('activate-customer', { path: '/activate-customer/:code' });

    this.route('my-bookings', function () {
      this.route('index', { path: '/' });
      this.route('view', { path: '/:booking_id' }, function () {});
    });

    this.route('book', function () {
      this.route('select-products');
      this.route('customize-product');
      this.route('confirm-basket');
      this.route('register-details');
      this.route('payment-centre');
    });

    this.route('book-addition', { path: '/book-addition/:booking_id' }, function () {
      this.route('select-products');
      this.route('payment-centre');
    });

    this.route('payment-callback');
    this.route('booking-confirm', { path: '/booking-confirm/:booking_id' });

    this.route('faq');
    this.route('terms');
    this.route('maps');
  });

  Router.reopen({
    init: function init() {
      var clientAddress = window.location.pathname.split("/")[1];
      if (isBlank(clientAddress)) {
        window.location.replace(window.location + "marketing/");
      } else {
        var rootURL = '/' + clientAddress + '/';
        console.log("rootURL " + rootURL);
        this.set('rootURL', rootURL);
        _environment.default.clientRootURL = rootURL;
        this._super();
      }
    }
  });

  exports.default = Router;
});