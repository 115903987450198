define('customisation-web-public/models/basket-item-customization', ['exports', 'customisation-web-public/models/customization-form-details'], function (exports, _customizationFormDetails) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var EmberObject = Ember.Object;
    var computed = Ember.computed;
    var _copy = Ember.copy;
    var isPresent = Ember.isPresent;


    var BasketItemCustomization = EmberObject.extend(Ember.Copyable, {
        material: null,
        background: null,
        formId: null,
        formName: null,
        formPreviewTemplate: null,
        formDetails: null,

        // transient (see toJSON())
        basketItem: null,
        errors: null,

        filled: computed('formDetails.@each.answer', function () {
            var formDetails = this.get('formDetails');

            return isPresent(formDetails) && formDetails.filter(function (fd) {
                return !fd.get('filled');
            }).length === 0;
        }),

        toJSON: function toJSON() {
            var ret = _copy(this, true);
            ret.setProperties({
                basketItem: undefined,
                errors: undefined,
                formDetails: this.get('formDetails') ? this.get('formDetails').map(function (d) {
                    return d.toJSON();
                }) : undefined
            });
            return ret;
        }
    });

    BasketItemCustomization.reopenClass({
        convert: function convert(json) {
            return BasketItemCustomization.create({
                material: json.material,
                background: json.background,
                basketItem: json.basketItem,
                formId: json.formId,
                formName: json.formName,
                formPreviewTemplate: json.formPreviewTemplate,
                formDetails: json.formDetails ? _customizationFormDetails.default.convertArray(json.formDetails) : undefined
            });
        },
        convertArray: function convertArray(jsonArray) {
            var _this = this;

            return jsonArray.map(function (item) {
                return _this.convert(item);
            });
        },
        createCustomization: function createCustomization(form, item) {
            var formDetails = form.get('elements').map(function (elem) {
                var answer = null;

                /*
                 * isTextShort = 'TEXT_SHORT'         - 1..N
                 * isTextLong  = 'TEXT_LONG'          - 1..N
                 * isRadio     = 'MULTIPLE_RADIO'     - 1
                 * isDropdown  = 'MULTIPLE_DROPDOWN'  - 1
                 * isCheckbox  = 'MULTIPLE_CHECKBOX'  - 1..N
                 * isListBox   = 'MULTIPLE_LISTBOX'   - 1..N
                 */
                switch (elem.get('answerType')) {
                    case 'TEXT_SHORT':
                    case 'TEXT_LONG':
                        answer = elem.get('answers').map(function (opt) {
                            return { value: '', placeholder: opt };
                        });
                        break;
                    case 'MULTIPLE_RADIO':
                    case 'MULTIPLE_DROPDOWN':
                        answer = [{ value: '' }];
                        break;
                    case 'MULTIPLE_CHECKBOX':
                        answer = elem.get('answers').map(function () {
                            return { value: false };
                        });
                        break;
                    case 'MULTIPLE_LISTBOX':
                        // TODO: This is wrong - should be empty array that is populated outside.
                        answer = elem.get('answers').map(function () {
                            return { value: null };
                        });
                        break;
                }

                return _customizationFormDetails.default.create({
                    formElement: elem,
                    answer: answer
                });
            });

            return BasketItemCustomization.create({
                basketItem: item.get('id'),
                formId: form.get('id'),
                formName: form.get('name'),
                formPreviewTemplate: form.get('previewTemplate'),
                formDetails: formDetails
            });
        }
    });

    BasketItemCustomization.reopen({
        copy: function copy(deep) {
            var ret = BasketItemCustomization.create(this.getProperties(Object.keys(this)));
            if (deep) {
                if (this.get('formDetails')) {
                    ret.set('formDetails', this.get('formDetails').map(function (d) {
                        return _copy(d, true);
                    }));
                }
            }
            return ret;
        }
    });

    exports.default = BasketItemCustomization;
});