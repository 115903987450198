define('customisation-web-public/pods/components/alert-messages/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component;
    var service = Ember.inject.service;
    exports.default = Component.extend({
        tagName: '',

        flashMessages: service()
    });
});