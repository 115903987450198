define('customisation-web-public/pods/book/confirm-basket/promotion-form-modal/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component;
    var service = Ember.inject.service;
    exports.default = Component.extend({
        modal: service(),
        errors: null,

        promotionCode: null,

        actions: {
            addPromotion: function addPromotion() {
                var _this = this;

                this.set('errors', null);

                this.get('addPromotion')(this.get('promotionCode')).then(function () {
                    _this.get('modal').hide();
                }).catch(function (errors) {
                    _this.set('errors', errors);
                });
            }
        }
    });
});