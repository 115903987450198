define('customisation-web-public/models/customer-address', ['exports', 'ember-i18n-iso-countries'], function (exports, _emberI18nIsoCountries) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var EmberObject = Ember.Object;
    var computed = Ember.computed;
    var isBlank = Ember.isBlank;
    var isPresent = Ember.isPresent;
    var copy = Ember.copy;


    var Address = EmberObject.extend(Ember.Copyable, {
        id: null,
        type: null,
        default: null,
        address1: null,
        address2: null,
        town: null,
        postcode: null,
        countryId: null,
        level1region: null,

        init: function init() {
            this._super.apply(this, arguments);
            if (!isPresent(this.get('level1region')) && isPresent(this.get('level1Region'))) {
                this.set('level1region', this.get('level1Region'));
            }
        },


        countryName: computed('countryId', function () {
            return (0, _emberI18nIsoCountries.getName)(this.get('countryId'), 'en');
        }),

        isEmpty: function isEmpty() {
            var _this = this;

            var nonEmptyProps = Object.keys(this).filter(function (k) {
                return k !== 'id' && k !== 'type' && k !== 'default';
            }).filter(function (k) {
                return !isBlank(_this.get(k));
            });

            return nonEmptyProps.length === 0;
        }
    });

    Address.reopen({
        copy: function copy() {
            return Address.create(this.getProperties(Object.keys(this)));
        }
    });

    exports.default = Address;
});