define('customisation-web-public/pods/components/customer-form/component', ['exports', 'ember-i18n-iso-countries'], function (exports, _emberI18nIsoCountries) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component;
    var computed = Ember.computed;
    var on = Ember.on;
    var inject = Ember.inject.service;
    var isPresent = Ember.isPresent;
    var $ = Ember.$;
    exports.default = Component.extend({
        tagName: '',

        basket: inject(),

        _setup: on('init', function () {
            this.set('confirmEmail', this.get('confirmEmail') || false);
        }),

        isDisableName: computed('customer.id', function () {
            return isPresent(this.get('customer.id')) && this.get('disableNameExist');
        }),
        showDeliveryInfo: computed('showDeliveryTotal', 'basket.data.deliveryTotal', function () {
            return this.get('showDeliveryTotal') && this.get('basket.data.deliveryTotal') > 0;
        }),

        showBillingState: computed('customer.mainAddress.countryId', function () {
            return this.get('customer.mainAddress.countryId') === 'US';
        }),

        countries: computed(function () {
            return (0, _emberI18nIsoCountries.getNames)('en');
        }),

        isSccc: computed('basket.project.client.id', function () {
            return "23b2150d-1020-4d02-8c66-08a42de2853d" === this.get('basket.project.data.client.id');
        }),

        _didInsertElement: on('didInsertElement', function () {
            /** Adding freeze class to input labels when value input & not focused **/
            var formFields = $('.has-float-label');
            formFields.each(function () {
                var field = $(this);
                var input = field.find('input');
                var label = field.find('label');

                function checkInput() {
                    var valueLength = input.val().length;

                    if (valueLength > 0) {
                        label.addClass('freeze');
                    } else {
                        label.removeClass('freeze');
                    }
                }
                input.change(function () {
                    checkInput();
                });
            });
        }),

        _didRender: on('didRender', function () {
            $(".selectpicker").selectpicker('refresh');
            $(".selectpicker").each(function () {
                var element = $(this);
                if (!element.hasClass("is-invalid")) {
                    element.parent().removeClass("is-invalid");
                }
            });
        }),

        usStates: computed(function () {
            return {
                'AL': 'Alabama',
                'AK': 'Alaska',
                'AS': 'America Samoa',
                'AZ': 'Arizona',
                'AR': 'Arkansas',
                'CA': 'California',
                'CO': 'Colorado',
                'CT': 'Connecticut',
                'DE': 'Delaware',
                'DC': 'District of Columbia',
                'FM': 'Micronesia1',
                'FL': 'Florida',
                'GA': 'Georgia',
                'GU': 'Guam',
                'HI': 'Hawaii',
                'ID': 'Idaho',
                'IL': 'Illinois',
                'IN': 'Indiana',
                'IA': 'Iowa',
                'KS': 'Kansas',
                'KY': 'Kentucky',
                'LA': 'Louisiana',
                'ME': 'Maine',
                'MH': 'Islands1',
                'MD': 'Maryland',
                'MA': 'Massachusetts',
                'MI': 'Michigan',
                'MN': 'Minnesota',
                'MS': 'Mississippi',
                'MO': 'Missouri',
                'MT': 'Montana',
                'NE': 'Nebraska',
                'NV': 'Nevada',
                'NH': 'New Hampshire',
                'NJ': 'New Jersey',
                'NM': 'New Mexico',
                'NY': 'New York',
                'NC': 'North Carolina',
                'ND': 'North Dakota',
                'OH': 'Ohio',
                'OK': 'Oklahoma',
                'OR': 'Oregon',
                'PW': 'Palau',
                'PA': 'Pennsylvania',
                'PR': 'Puerto Rico',
                'RI': 'Rhode Island',
                'SC': 'South Carolina',
                'SD': 'South Dakota',
                'TN': 'Tennessee',
                'TX': 'Texas',
                'UT': 'Utah',
                'VT': 'Vermont',
                'VI': 'Virgin Island',
                'VA': 'Virginia',
                'WA': 'Washington',
                'WV': 'West Virginia',
                'WI': 'Wisconsin',
                'WY': 'Wyoming' };
        }),

        actions: {
            resendActivation: function resendActivation() {
                this.get('resendActivation')(this.get('customer'));
            }
        }
    });
});