define('customisation-web-public/pods/book/customize-product/edit-customization-modal/component', ['exports', 'ember-sf-modal/mixins/modal', 'customisation-web-public/models/basket-item-customization'], function (exports, _modal, _basketItemCustomization) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component;
    var EmberError = Ember.Error;
    var on = Ember.on;
    var copy = Ember.copy;
    var isPresent = Ember.isPresent;
    var typeOf = Ember.typeOf;
    exports.default = Component.extend(_modal.default, {
        _setup: on('init', function () {
            var item = this.get('item');
            var product = item.get('customizedProduct');

            if (isPresent(item) && isPresent(this.get('form'))) {
                // 1. item + form

                var customization = item.get('customization');

                this.set('customization', isPresent(customization) ? copy(customization, true) : this.createItemCustomization());
            } else if (isPresent(product)) {
                // 2. item.customizedProduct

                this.set('customization', copy(product.get('customization'), true));
            } else {
                throw new EmberError('The product is not customizable');
            }
        }),

        createItemCustomization: function createItemCustomization() {
            return _basketItemCustomization.default.createCustomization(this.get('form'), this.get('item'));
        },


        actions: {
            saveCustomization: function saveCustomization() {
                var _this = this;

                this.set('customization.errors', null);
                this.get('customization.formDetails').forEach(function (d) {
                    return d.set('errors', null);
                });

                var customization = this.get('customization');

                return this.get('save')(customization).catch(function (errors) {
                    var statusError = errors.status;
                    var detailsErrors = errors.customization && errors.customization.formDetails;

                    if (typeOf(detailsErrors) === 'object') {
                        // Extend each formDetail item with related error if present.
                        // errors.customization.formDetails.N.answer.N.value

                        var formDetails = _this.get('customization.formDetails');

                        var _iteratorNormalCompletion = true;
                        var _didIteratorError = false;
                        var _iteratorError = undefined;

                        try {
                            for (var _iterator = Object.keys(detailsErrors)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                                var i = _step.value;

                                formDetails[i].set('errors', detailsErrors[i].answer);
                            }
                        } catch (err) {
                            _didIteratorError = true;
                            _iteratorError = err;
                        } finally {
                            try {
                                if (!_iteratorNormalCompletion && _iterator.return) {
                                    _iterator.return();
                                }
                            } finally {
                                if (_didIteratorError) {
                                    throw _iteratorError;
                                }
                            }
                        }
                    } else {
                        // Set customization global error.

                        _this.set('customization.errors', statusError || detailsErrors);
                    }
                    return errors;
                });
            }
        }
    });
});