define('customisation-web-public/pods/components/booking-steps/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component;
    var on = Ember.on;
    exports.default = Component.extend({
        tagName: '',

        _setup: on('init', function () {
            var _this = this;

            var createItem = function createItem(step, title, route) {
                var currentStep = _this.get('step');

                return {
                    step: step,
                    title: title,
                    route: route,
                    cssClass: (step === currentStep ? 'current' : step > currentStep ? 'after-current' : 'first') + (step === 6 ? ' last' : '')
                };
            };

            this.set('steps', [createItem(1, 'Select plaques', 'book.select-products'), createItem(2, 'Add inscription', 'book.customize-product'), createItem(3, 'Confirm basket', 'book.confirm-basket'), createItem(4, 'Register details', 'book.register-details'), createItem(5, 'Payment centre', 'book.payment-centre'), createItem(6, 'Confirmation', '')]);
        })
    });
});