define('customisation-web-public/models/customization-form-element', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model', 'customisation-web-public/models/project'], function (exports, _jsonapiModel, _project) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var EmberObject = Ember.Object;
    var computed = Ember.computed;
    var copy = Ember.copy;


    var CustomizationFormElement = EmberObject.extend(Ember.Copyable, {
        id: null,
        project: null,
        name: null,
        question: null,
        questionClass: null,
        answerType: null,
        answers: null,
        maxLength: null,
        allowedChars: null,
        disallowSwearWords: null,

        isTextShort: computed('answerType', function () {
            return this.get('answerType') === 'TEXT_SHORT';
        }),
        isTextLong: computed('answerType', function () {
            return this.get('answerType') === 'TEXT_LONG';
        }),
        isRadio: computed('answerType', function () {
            return this.get('answerType') === 'MULTIPLE_RADIO';
        }),
        isDropdown: computed('answerType', function () {
            return this.get('answerType') === 'MULTIPLE_DROPDOWN';
        }),
        isCheckbox: computed('answerType', function () {
            return this.get('answerType') === 'MULTIPLE_CHECKBOX';
        }),
        isListBox: computed('answerType', function () {
            return this.get('answerType') === 'MULTIPLE_LISTBOX';
        }),

        toJSON: function toJSON() {
            var result = copy(this, true);
            result.set('maxLength', parseInt(this.get('maxLength')));
            return result;
        }
    });

    CustomizationFormElement.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'customization-form-elements',
            relationships: {
                project: _project.default
            }
        },
        convert: function convert(json) {
            return CustomizationFormElement.create({
                id: json.id,
                name: json.name,
                question: json.question,
                questionClass: json.questionClass,
                answerType: json.answerType,
                answers: json.answers,
                maxLength: json.maxLength,
                allowedChars: json.allowedChars,
                disallowSwearWords: json.disallowSwearWords
            });
        }
    });

    CustomizationFormElement.reopen({
        copy: function copy() {
            return CustomizationFormElement.create(this.getProperties(Object.keys(this)));
        }
    });

    exports.default = CustomizationFormElement;
});