define('customisation-web-public/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Route = Ember.Route;
    var service = Ember.inject.service;
    var RSVP = Ember.RSVP;
    exports.default = Route.extend(_applicationRouteMixin.default, {
        session: service(),
        project: service(),
        modal: service(),

        beforeModel: function beforeModel() {
            var _this = this;

            // Wrapped into RSVP.resolve() to make sure the result of _super() is a promise.
            var superPromise = RSVP.resolve(this._super.apply(this, arguments));

            var domain = window.location.pathname.split("/")[1];

            return superPromise.then(function () {
                return _this.get('project').setup(domain);
            });

            /*
            return new Promise(resolve => {
                this.get('project').setup()
                    .then(() => resolve())
                    .catch(() => {
                        // If the invalid project has been specified - do a redirect.
                        if (this.get('client.domain')) {
                            const origin = document.location.origin.replace(`${this.get('client.domain')}.${ROOT_HOST}`, ROOT_HOST);
                            document.location.href = `${origin}${baseURL}`;
                            return;
                        }
                         // If the client has not been specified (bare domain requested) - just transition to the landing page.
                        resolve();
                        this.transitionTo('index');
                    });
            });
            */
        },
        sessionAuthenticated: function sessionAuthenticated() {
            var noTransition = this.get('session.noTransitionOnSessionDataUpdated');

            if (noTransition) {
                this.set('session.noTransitionOnSessionDataUpdated', false);
                this.refresh();
            } else {
                this._super.apply(this, arguments);
            }
        },


        actions: {
            didTransition: function didTransition() {
                window.scrollTo(0, 0);
            },
            showLoginModal: function showLoginModal() {
                this.get('modal').show('login-modal', {
                    title: 'Login'
                });
            }
        }
    });
});