define("customisation-web-public/locales/en/translations", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        "errors.ERR_NO_APPLICABLE_ITEMS_FOUND": "Your session may have expired, please go back and try again",
        "errors.unknown.payment.error": "Unknown payment error occurred please contact support",

        "channel.telesales": "Telesales",
        "channel.public": "Online",

        "bookings.status.PENDING": "Pending Payment",
        "bookings.status.AUTHORISED": "Confirmed",
        "bookings.status.CANCELLED": "Cancelled",

        "customizedProduct.status.VOUCHER": "Voucher",
        "customizedProduct.status.AMENDABLE": "Inscription amendable",
        "customizedProduct.status.NOT_AMENDABLE": "No further changes can be made",
        "customizedProduct.status.IN_PRODUCTION": "In production",
        "customizedProduct.status.FULFILLED": "Installed",
        "customizedProduct.status.CANCELLED": "Cancelled",

        "customizedProduct.material.zinc": "Etched Zinc Plaques",
        "customizedProduct.material.aluminium": "Printed Aluminium Plaques",
        "customizedProduct.material.acrylic": "Reverse Printed Acrylic Plaques",
        "customizedProduct.material.brick": "Engraved Brick Slips",
        "customizedProduct.material.granite": "Engraved Granite Tiles",

        "customizedProduct.material.description.zinc": "Personalise your own quality Zinc plaque with an inscription commemorating your own significant moments and memories.",
        "customizedProduct.material.description.aluminium": "Aluminium plaques are personalised with your own inscription commemorating your own significant moments and memories.",
        "customizedProduct.material.description.acrylic": "Expertly crafted Acrylic plaques personalised with your own inscription commemorating your own significant moments and memories",
        "customizedProduct.material.description.brick": "Personalise your own quality Brick slip with an inscription commemorating your own significant moments and memories.",
        "customizedProduct.material.description.granite": "Personalise your own quality Granite tile with an inscription commemorating your own significant moments and memories."
    };
});