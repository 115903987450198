define('customisation-web-public/pods/book/select-products/product-item/component', ['exports', 'customisation-web-public/utils/range'], function (exports, _range) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component;
    var on = Ember.on;
    var isBlank = Ember.isBlank;


    var MAX_QTY = 10;

    exports.default = Component.extend({
        tagName: '',
        _setup: on('init', function () {
            var maxQty = Math.min(MAX_QTY, this.get('product.available'));
            this.set('quantities', (0, _range.range)(1, maxQty));
        }),

        actions: {
            selectProduct: function selectProduct() {
                this.get('bookProduct')(this.get('product'), 1);
            }
        }
    });
});