define('customisation-web-public/models/basket-item-list', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model'], function (exports, _jsonapiModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var EmberObject = Ember.Object;


    var BasketItemList = EmberObject.extend({
        items: null
    });

    BasketItemList.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'basket-item-lists'
        }
    });

    exports.default = BasketItemList;
});