define('customisation-web-public/models/basket', ['exports', 'big.js', 'ember-sf-jsonapi-model/mixins/jsonapi-model', 'customisation-web-public/models/client', 'customisation-web-public/models/project', 'customisation-web-public/models/customer', 'customisation-web-public/models/booking-group', 'customisation-web-public/utils/collectors'], function (exports, _big, _jsonapiModel, _client, _project, _customer, _bookingGroup, _collectors) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var EmberObject = Ember.Object;
    var computed = Ember.computed;
    var copy = Ember.copy;
    var isPresent = Ember.isPresent;


    var Basket = EmberObject.extend(Ember.Copyable, {
        id: null,
        client: null,
        partner: null,
        items: null,
        bookingGroups: null,
        billing: null,
        shipping: null,
        discountAmount: null,
        discountPercent: null,
        currency: null,
        total: null,
        customer: null,
        promotionAmount: null,
        promotionCode: null,
        info: null,

        init: function init() {
            this.setProperties({
                items: this.get('items') || [],
                bookingGroups: this.get('bookingGroups') || []
            });
        },


        sortedGroups: computed('bookingGroups.@each.id', function () {
            return this.get('bookingGroups').sortBy('id');
        }),
        itemsQty: computed('bookingGroups', function () {
            var bookingGroups = this.get('bookingGroups');
            var itemsQty = 0;

            if (isPresent(bookingGroups)) {
                for (var i = 0; i < bookingGroups.length; i++) {
                    for (var k = 0; k < bookingGroups[i].get('items').length; k++) {
                        itemsQty += bookingGroups[i].get('items')[k].get('quantity');
                    }
                }
            }

            return itemsQty;
        }),
        hasProducts: computed('itemsQty', 'bookingGroups.[]', 'bookingGroups.@each.bookingGroup', function () {
            return this.get('itemsQty') > 0;
        }),
        groupItems: computed('bookingGroups.@each.id', function () {
            return (0, _collectors.flatMap)(this.get('bookingGroups'), function (bg) {
                return bg.get('items');
            });
        }),

        deliveryTotal: computed('groupItems.@each.product', function () {
            var deliveryTotal = this.get('groupItems').reduce(function (accum, item) {
                if (item.get('productGroup') === 'Deliveries') {
                    accum = accum.plus(item.get('price'));
                }

                return accum;
            }, new _big.default(0));

            return Number(deliveryTotal);
        }),

        containsProduct: function containsProduct(product) {
            var items = this.get('bookingGroups').reduce(function (accum, bg) {
                accum.pushObjects(bg.get('items'));
                return accum;
            }, []);

            return !!items.findBy('product', product.get('id'));
        },
        isCustomizationsFilled: function isCustomizationsFilled() {
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = this.get('bookingGroups')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var group = _step.value;
                    var _iteratorNormalCompletion2 = true;
                    var _didIteratorError2 = false;
                    var _iteratorError2 = undefined;

                    try {
                        for (var _iterator2 = group.get('items')[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                            var item = _step2.value;

                            if (item.get('customizationRequired')) {
                                var customization = item.get('customization');

                                if (!isPresent(customization) || !customization.get('filled')) {
                                    return false;
                                }
                            }
                        }
                    } catch (err) {
                        _didIteratorError2 = true;
                        _iteratorError2 = err;
                    } finally {
                        try {
                            if (!_iteratorNormalCompletion2 && _iterator2.return) {
                                _iterator2.return();
                            }
                        } finally {
                            if (_didIteratorError2) {
                                throw _iteratorError2;
                            }
                        }
                    }
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }

            return true;
        },
        fromJSON: function fromJSON() {
            var ret = copy(this, true);

            if (isPresent(this.get('bookingGroups'))) {
                ret.set('bookingGroups', _bookingGroup.default.convertArray(this.get('bookingGroups')));
            }

            return ret;
        }
    });

    Basket.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'baskets',
            relationships: {
                client: _client.default,
                partner: _project.default,
                customer: _customer.default
            }
        }
    });

    Basket.reopen({
        copy: function copy() {
            return Basket.create(this.getProperties(Object.keys(this)));
        }
    });

    exports.default = Basket;
});