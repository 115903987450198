define('customisation-web-public/pods/forgotten-password/route', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'customisation-web-public/config/environment', 'customisation-web-public/models/password-request'], function (exports, _unauthenticatedRouteMixin, _environment, _passwordRequest) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Route = Ember.Route;
    var inject = Ember.inject.service;
    var run = Ember.run;
    var SERVICES = _environment.default.SERVICES;
    exports.default = Route.extend(_unauthenticatedRouteMixin.default, {
        ajax: inject(),
        project: inject(),

        actions: {
            sendRequest: function sendRequest(username) {
                var _this = this;

                var passwordRequest = _passwordRequest.default.create({
                    email: username,
                    client: this.get('project.data.client.id')
                });
                var requestData = _passwordRequest.default.stringify(passwordRequest);

                return this.get('ajax').post(SERVICES.customers + '/auth/password-requests', requestData).then(function () {
                    run.later(function () {
                        return _this.transitionTo('login');
                    }, 5000);
                });
            }
        }
    });
});