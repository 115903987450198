define('customisation-web-public/pods/book/confirm-basket/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Route = Ember.Route;
    var service = Ember.inject.service;
    var RSVP = Ember.RSVP;
    exports.default = Route.extend({
        basket: service(),
        modal: service(),

        beforeModel: function beforeModel(transition) {
            var _this = this;

            return this.get('basket').setup(true).then(function () {
                return _this.validateBasketState(transition);
            }).then(function () {
                return _this.get('basket').refreshDeliveryProducts();
            });
        },
        validateBasketState: function validateBasketState(transition) {
            var target = null;

            if (!this.get('basket.data.hasProducts')) {
                target = 'book.select-products';
            } else if (!this.get('basket.data').isCustomizationsFilled()) {
                target = 'book.customize-product';
            }

            if (target) {
                if (transition) {
                    transition.abort();
                }

                this.transitionTo(target);
                return RSVP.reject();
            }

            return RSVP.resolve();
        },
        addPromotion: function addPromotion(promotionCode) {
            var _this2 = this;

            return this.get('basket').addPromotion(promotionCode).then(function () {
                _this2.refresh();
            });
        },


        actions: {
            removeGroup: function removeGroup(group) {
                var _this3 = this;

                var ids = group.items.map(function (i) {
                    return i.id;
                });

                this.get('basket').removeItems(ids).then(function () {
                    return _this3.validateBasketState();
                });
            },
            showPromotionModal: function showPromotionModal() {
                this.get('modal').show('book/confirm-basket/promotion-form-modal', {
                    addPromotion: this.addPromotion.bind(this)
                });
            }
        }
    });
});