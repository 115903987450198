define('customisation-web-public/pods/components/examples-flickity/component', ['exports', 'customisation-web-public/utils/range', 'customisation-web-public/config/environment'], function (exports, _range, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component;
    var on = Ember.on;
    var $ = Ember.$;
    var rootURL = _environment.default.rootURL;
    exports.default = Component.extend({
        tagName: 'div',
        classNames: ['carousel-wrap'],
        rootURL: rootURL,

        _didInsertElement: on('didInsertElement', function () {
            this.set('$element', $(this.get('element')).find('.carousel-flick'));

            var options = {
                cellAlign: 'center',
                contain: true,
                wrapAround: true,
                autoPlay: true,
                groupCells: 2 //because there is 4 cells, without this, the carousel would not be centered.
            };

            // enable prev/next buttons at 768px
            if (matchMedia('screen and (max-width: 767px)').matches) {
                options.groupCells = 1;
            }

            this.get('$element').flickity(options);

            // select the buttons
            var buttons = $('.tile-carousel-1 .flickity-button');

            // add them to a newly created div
            var new_div = $('<div class="container custom-flickity-container"></div>').append(buttons);

            // append the new div (my-div) to .main-carousel
            $('.tile-carousel-1').append(new_div);
        })
    });
});