define('customisation-web-public/models/project', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model', 'customisation-web-public/models/client', 'customisation-web-public/models/website'], function (exports, _jsonapiModel, _client, _website) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var EmberObject = Ember.Object;


    var Project = EmberObject.extend({
        id: null,
        name: null,
        client: null
    });

    Project.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'projects',
            relationships: {
                client: _client.default,
                website: _website.default
            }
        }
    });

    exports.default = Project;
});