define('customisation-web-public/pods/book-addition/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Route = Ember.Route;
    exports.default = Route.extend(_authenticatedRouteMixin.default, {
        model: function model(_ref) {
            var booking_id = _ref.booking_id;

            return booking_id;
        }
    });
});