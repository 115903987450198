define('customisation-web-public/pods/components/content-wrapper/component', ['exports', 'customisation-web-public/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component;
    var service = Ember.inject.service;
    var computed = Ember.computed;
    var on = Ember.on;
    var isPresent = Ember.isPresent;
    var $ = Ember.$;
    var rootURL = _environment.default.rootURL,
        clientRootURL = _environment.default.clientRootURL;
    exports.default = Component.extend({
        session: service(),
        modal: service(),
        basket: service(),
        project: service(),

        rootURL: rootURL,
        clientRootURL: clientRootURL,

        _setup: on('init', function () {
            this.set('showLoginLink', !isPresent(this.get('showLoginLink')) || this.get('showLoginLink'));
        }),

        componentName: Ember.computed('project.data.website.customization.design', function () {
            var design = this.get('project.data.website.customization.design');
            if (design === 'design1') {
                return 'content-wrapper-design-1';
            } else if (design === 'design2') {
                return 'content-wrapper-design-2';
            } else if (design === 'design3') {
                return 'content-wrapper-design-3';
            } else {
                return 'content-wrapper-design-1';
            }
        }),

        _didInsertElement: on('didInsertElement', function () {
            //set css depends on selected design
            var websiteDesign = this.get('project.data.website.customization.design');
            var cssLink = $('link[href*="public-styles.css"]');
            if (cssLink.length > 0) {
                var oldLink = cssLink.attr('href');
                cssLink.attr('href', oldLink.replace(".css", "-" + websiteDesign + '.css'));
            }
            //Smooth Scroll
            // Select all links with hashes
            $('a[href*="#"]')
            // Remove links that don't actually link to anything
            .not('[href="#"]').not('[href="#0"]').click(function (event) {
                // On-page links
                if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                    // Figure out element to scroll to
                    var target = $(this.hash);
                    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                    // Does a scroll target exist?
                    if (target.length) {
                        // Only prevent default if animation is actually gonna happen
                        event.preventDefault();
                        $('html, body').animate({
                            scrollTop: target.offset().top
                        }, 1000, function () {
                            // Callback after animation
                            // Must change focus!
                            var $target = $(target);
                            $target.focus();
                            if ($target.is(":focus")) {
                                // Checking if the target was focused
                                return false;
                            } else {
                                $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                                $target.focus(); // Set focus again
                            };
                        });
                    }
                }
            });
        }),

        groupsCount: computed('basket.data.bookingGroups.@each', function () {
            var groups = this.get('basket.data.bookingGroups');
            return groups && groups.length;
        }),
        emptyBasket: computed('groupsCount', function () {
            return this.get('groupsCount') === 0;
        }),

        actions: {
            showLoginModal: function showLoginModal() {
                this.get('modal').show('login-modal', {
                    title: 'Login'
                });
            },
            invalidateSession: function invalidateSession() {
                this.get('session').invalidate();
            }
        }
    });
});