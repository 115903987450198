define('customisation-web-public/initializers/modal-service', ['exports', 'ember-sf-modal/initializers/modal-service'], function (exports, _modalService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _modalService.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _modalService.initialize;
    }
  });
});