define('customisation-web-public/services/project', ['exports', 'customisation-web-public/config/environment', 'customisation-web-public/models/project'], function (exports, _environment, _project) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Service = Ember.Service;
    var service = Ember.inject.service;
    var Evented = Ember.Evented;
    var RSVP = Ember.RSVP;
    var SERVICES = _environment.default.SERVICES;
    exports.default = Service.extend(Evented, {
        ajax: service(),

        domain: null,
        data: null,
        website: null,

        setup: function setup(domain) {
            var _this = this;

            var forceReload = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

            // If the setup promise has already been initialised - return the original instance.
            if (!forceReload && this.get('_setupPromise')) {
                return this.get('_setupPromise');
            }

            var setupPromise = new RSVP.Promise(function (resolve, reject) {
                _this.set('data', _project.default.create());

                /*
                const rootHostIndex = document.location.hostname.indexOf(`.${config.ROOT_HOST}`);
                if (rootHostIndex === -1) {
                    this.set('domain', '');
                    reject();
                    return;
                }
                 const clientDomain = document.location.hostname.slice(0, rootHostIndex);
                this.set('domain', clientDomain);
                */

                _this.get('ajax').fetchModel(_project.default, SERVICES.projects + '?filter[domain]=' + domain).then(function (project) {
                    _this.set('data', project);

                    resolve();

                    /*
                    this.get('ajax').fetchModel(ClientWebsite, `${SERVICES.clients}/${client.id}/website`)
                        .then(website => {
                            this.set('website', website);
                             resolve();
                        }, () => reject());
                    */
                }, function (error) {
                    return reject(error);
                });
            });

            this.set('_setupPromise', setupPromise);

            return setupPromise;
        }
    });
});