define('customisation-web-public/pods/book/select-products/products-list/component', ['exports', 'customisation-web-public/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component;
    var rootURL = _environment.default.rootURL;
    exports.default = Component.extend({
        rootURL: rootURL
    });
});