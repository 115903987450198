define('customisation-web-public/pods/book/select-products/route', ['exports', 'customisation-web-public/config/environment', 'ember-sf-pageable/mixins/pageable-route', 'customisation-web-public/models/product', 'customisation-web-public/utils/urls'], function (exports, _environment, _pageableRoute, _product, _urls) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Route = Ember.Route;
    var service = Ember.inject.service;
    var RSVP = Ember.RSVP;
    var isEmpty = Ember.isEmpty;
    var rootURL = _environment.default.rootURL,
        SERVICES = _environment.default.SERVICES;
    exports.default = Route.extend(_pageableRoute.default, {
        project: service(),
        basket: service(),
        ajax: service(),

        pageableModel: function pageableModel(params) {
            var project = this.get('project.data');
            var productsPromise = this.loadPage('products', _product.default, (0, _urls.buildFilterUrl)(SERVICES.products, {
                client: project.get('client.id'),
                event: project.get('id'),
                productGroup: 'Products'
            }), params);

            var material = productsPromise.then(function (products) {
                return isEmpty(products.data) ? null : products.data.get(0).get('meta.material');
            });
            return {
                rootURL: rootURL,
                material: material,
                products: productsPromise,
                website: project.website
            };
        },


        actions: {
            bookProduct: function bookProduct(product, quantity) {
                var _this = this;

                return this.get('basket').persist(product.get('currency')).then(function () {
                    product.set('qty', 1);

                    var products = [];
                    for (var i = 0; i < quantity; i++) {
                        products.pushObject(product);
                    }

                    // TODO: Temporary solution, hopefully.
                    // Add all products in order waiting for the promise to resolve before running the next one.
                    products.reduce(function (current, p) {
                        return current.then(function () {
                            return _this.get('basket').addProducts([p]);
                        });
                    }, RSVP.resolve()).then(function () {
                        return _this.transitionTo('book.customize-product');
                    });

                    // return this.get('basket')._addProducts({
                    //     products: products,
                    //     unique:   false,
                    //     grouped:  false
                    // }).then(() => this.transitionTo('book.customize-product'));
                });
            }
        }
    });
});