define('customisation-web-public/pods/book-addition/select-products/route', ['exports', 'customisation-web-public/config/environment', 'customisation-web-public/mixins/view-booking-route', 'customisation-web-public/utils/urls', 'customisation-web-public/utils/collectors', 'customisation-web-public/models/product', 'customisation-web-public/models/booking', 'customisation-web-public/models/delivery-region', 'customisation-web-public/pods/book-addition/select-products/booking-group'], function (exports, _environment, _viewBookingRoute, _urls, _collectors, _product, _booking, _deliveryRegion, _bookingGroup) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Route = Ember.Route;
    var inject = Ember.inject.service;
    var get = Ember.get;
    var set = Ember.set;
    var isEmpty = Ember.isEmpty;
    var RSVP = Ember.RSVP;
    var SERVICES = _environment.default.SERVICES;
    exports.default = Route.extend(_viewBookingRoute.default, {
        ajax: inject(),
        ajaxproj: inject(),
        project: inject(),
        basket: inject(),

        model: function model() {
            var _this = this;

            var bookingId = this.modelFor('book-addition');
            var bookingPromise = this.fetchBooking(bookingId);

            var bookingGroupsPromise = bookingPromise.then(function (booking) {
                return _this.fetchExtraProducts(booking).then(function (extras) {
                    // TODO: Replace with session.currentCustomer?
                    var countryId = booking.get('customer.mainAddress.countryId');

                    return _this.fetchDeliveryProducts(extras, countryId).then(function (deliveries) {
                        return _this.buildBookingGroups(booking, extras, deliveries);
                    });
                });
            });

            return RSVP.hash({
                booking: bookingPromise,
                bookingGroups: bookingGroupsPromise
            });
        },
        fetchExtraProducts: function fetchExtraProducts(booking) {
            var basketItems = (0, _collectors.flatMap)(booking.get('bookingGroups'), function (bg) {
                return bg.get('items');
            });
            var productsIds = (0, _collectors.uniqueMap)(basketItems, function (bi) {
                return bi.get('product');
            });

            return this.get('ajax').fetchList(_product.default, (0, _urls.buildFilterUrl)(SERVICES.products, {
                event: this.get('projectId'),
                productGroup: 'Extras',
                with: productsIds
            }));
        },
        fetchDeliveryProducts: function fetchDeliveryProducts(products, countryId) {
            var _this2 = this;

            var clientId = this.get('project.data.client.id');
            var regionsPromise = this.get('ajaxproj').fetchList(_deliveryRegion.default, (0, _urls.buildFilterUrl)(SERVICES.deliveryRegions, {
                client: clientId,
                country: countryId
            }));

            return regionsPromise.then(function (regions) {
                return _this2.get('ajax').fetchList(_product.default, (0, _urls.buildFilterUrl)(SERVICES.products, {
                    client: clientId,
                    event: regions.map(function (r) {
                        return r.get('id');
                    }),
                    productGroup: 'Deliveries',
                    with: products.map(function (p) {
                        return p.get('id');
                    })
                }));
            });
        },
        buildBookingGroups: function buildBookingGroups(booking, extras, deliveries) {
            return booking.get('sortedGroups').map(function (bg) {
                return _bookingGroup.default.create({
                    bookingGroup: bg,
                    extras: extras,
                    deliveries: deliveries
                });
            });
        },
        updateEditedItems: function updateEditedItems() {
            var booking = this.get('currentModel.booking');
            var bookingGroups = this.get('currentModel.bookingGroups');

            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = bookingGroups[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var bg = _step.value;

                    var editedItems = bg.get('availableProducts').filter(function (i) {
                        return !isEmpty(i.get('bookingItem')) && i.get('bookingItem.quantity') !== i.get('quantity');
                    });

                    if (editedItems.length === 0) {
                        continue;
                    }

                    var bookingItemsMap = (0, _collectors.hashMap)(booking.get('items'));

                    var _iteratorNormalCompletion2 = true;
                    var _didIteratorError2 = false;
                    var _iteratorError2 = undefined;

                    try {
                        for (var _iterator2 = editedItems[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                            var item = _step2.value;

                            var bookingItem = bookingItemsMap[item.get('bookingItem.id')];

                            if (bookingItem) {
                                set(bookingItem, 'quantity', item.get('quantity') || 0);

                                var deliveryItem = bookingItemsMap[item.get('deliveryItem.id')];
                                if (!isEmpty(deliveryItem)) {
                                    set(deliveryItem, 'quantity', item.get('quantity') || 0);
                                }
                            }
                        }
                    } catch (err) {
                        _didIteratorError2 = true;
                        _iteratorError2 = err;
                    } finally {
                        try {
                            if (!_iteratorNormalCompletion2 && _iterator2.return) {
                                _iterator2.return();
                            }
                        } finally {
                            if (_didIteratorError2) {
                                throw _iteratorError2;
                            }
                        }
                    }
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }
        },
        collectNewItems: function collectNewItems() {
            var _this3 = this;

            var bookingGroups = this.get('currentModel.bookingGroups');

            return (0, _collectors.flatMap)(bookingGroups.map(function (bg) {
                return (0, _collectors.flatMap)(bg.get('availableProducts').filter(function (i) {
                    return isEmpty(i.get('bookingItem')) && i.get('quantity') > 0;
                }).map(function (i) {
                    var bookingGroup = bg.get('bookingGroup.bookingGroup');

                    var item = _this3.get('basket').createBasketItem(bookingGroup, i.get('product'), i.get('quantity'));
                    item.set('product', i.get('product.id'));

                    var deliveryMeta = { deliveryFor: item.get('product') };
                    var deliveryItem = _this3.get('basket').createBasketItem(bookingGroup, i.get('deliveryProduct'), i.get('quantity'), deliveryMeta);
                    deliveryItem.set('product', i.get('deliveryProduct.id'));

                    return [item, deliveryItem];
                }));
            }));
        },


        actions: {
            nextStep: function nextStep() {
                var _this4 = this;

                this.updateEditedItems();

                var newItems = this.collectNewItems();
                var booking = this.get('currentModel.booking');

                var softPencilItems = booking.get('items').filter(function (i) {
                    return get(i, 'status') === 'SOFT_PENCIL';
                });

                if (newItems.length > 0 || softPencilItems.length > 0) {
                    booking.get('items').pushObjects(newItems);

                    this.get('ajax').patch(SERVICES.bookings + '/bookings/' + booking.id, _booking.default.stringify(booking), _booking.default).then(function () {
                        return _this4.transitionTo('book-addition.payment-centre');
                    });
                }
            }
        }
    });
});