define('customisation-web-public/components/loadable-content', ['exports', 'ember-sf-loadable-content/components/loadable-content'], function (exports, _loadableContent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _loadableContent.default;
    }
  });
});