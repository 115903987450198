define('customisation-web-public/pods/book-addition/select-products/booking-item', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var EmberObject = Ember.Object;


    var BasketItem = EmberObject.extend({
        bookingItem: null,
        deliveryItem: null,
        product: null,
        quantity: null,
        deliveryProduct: null
    });

    exports.default = BasketItem;
});