define('customisation-web-public/pods/book-addition/payment-centre/redirect-form/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component;
    var inject = Ember.inject.service;
    exports.default = Component.extend({
        basket: inject(),

        init: function init() {
            this._super.apply(this, arguments);

            this.addObserver('redirectData.ready', this, 'doRedirect');
        },
        willDestroyElement: function willDestroyElement() {
            this._super.apply(this, arguments);

            this.removeObserver('redirectData.ready', this, 'doRedirect');
        },
        doRedirect: function doRedirect() {
            var redirectData = this.get('redirectData');
            console.info("Redirect");
            console.info(redirectData);

            if (redirectData.ready) {
                var $form = this.$('form');

                var field = function field(name, x) {
                    $form.find('input[name=' + name + ']').val(x);
                };

                // field('responseFailURL',            redirectData.responseFailURL);
                // field('responseSuccessURL',         redirectData.responseSuccessURL);
                // field('transactionNotificationURL', redirectData.notificationURL);
                field('merchantReturnData', redirectData.finishURL);
                field('merchantReference', redirectData.merchantReference);
                field('paymentAmount', redirectData.paymentAmount);
                field('currencyCode', redirectData.currencyCode);
                field('merchantAccount', redirectData.merchantAccount);
                field('skinCode', redirectData.skinCode);
                field('shopperLocale', redirectData.shopperLocale);
                field('shopperEmail', redirectData.shopperEmail);
                field('countryCode', redirectData.countryCode);
                field('sessionValidity', redirectData.sessionValidity);
                field('shipBeforeDate', redirectData.shipBeforeDate);
                field('merchantSig', redirectData.SIGNATURE);

                $form.attr('action', redirectData.checkoutUrl).submit();
            }
        }
    });
});