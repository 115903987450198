define('customisation-web-public/pods/my-bookings/view/items-list/component', ['exports', 'customisation-web-public/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component;
    var computed = Ember.computed;
    var rootURL = _environment.default.rootURL;
    exports.default = Component.extend({
        rootURL: rootURL,
        items: computed('bookingGroup.sortedItems', function () {
            var excludeSoftPencil = this.get('excludeSoftPencil');

            return this.get('bookingGroup.sortedItems').filter(function (i) {
                return !(i.get('isDelivery') || excludeSoftPencil && i.get('isSoftPencil'));
            });
        })
    });
});