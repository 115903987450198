define('customisation-web-public/models/client', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model'], function (exports, _jsonapiModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var EmberObject = Ember.Object;


    var Client = EmberObject.extend({
        id: null
    });

    Client.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'clients'
        }
    });

    exports.default = Client;
});