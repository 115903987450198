define('customisation-web-public/pods/components/images-flickity/component', ['exports', 'customisation-web-public/utils/range', 'customisation-web-public/config/environment'], function (exports, _range, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component;
    var on = Ember.on;
    var $ = Ember.$;
    var rootURL = _environment.default.rootURL;
    exports.default = Component.extend({
        tagName: 'div',
        classNames: ['carousel-wrap'],
        rootURL: rootURL,

        _setup: on('init', function () {
            this.set('images', (0, _range.range)(1, 8));
        }),

        _didInsertElement: on('didInsertElement', function () {
            this.set('$element', $(this.get('element')).find('.carousel-flick'));

            var options = {
                cellAlign: 'center',
                contain: true,
                wrapAround: true,
                autoPlay: true,
                groupCells: 2 //because there is 4 cells, without this, the carousel would not be centered.
            };

            // enable prev/next buttons at 768px
            if (matchMedia('screen and (max-width: 767px)').matches) {
                options.groupCells = 1;
            }

            this.get('$element').flickity(options);
        })
    });
});