define('customisation-web-public/models/payment-info', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model'], function (exports, _jsonapiModel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var EmberObject = Ember.Object;


    var PaymentInfo = EmberObject.extend({
        paymentMethod: null,
        currency: null,
        termsAccepted: null,
        payment: null,
        stripeToken: null
    });

    PaymentInfo.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'payment-info'
        }
    });

    exports.default = PaymentInfo;
});