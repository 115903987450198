define('customisation-web-public/pods/book/customize-product/extra-item/component', ['exports', 'customisation-web-public/utils/range', 'customisation-web-public/config/environment'], function (exports, _range, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component;
    var service = Ember.inject.service;
    var on = Ember.on;
    var isEmpty = Ember.isEmpty;
    var $ = Ember.$;
    var rootURL = _environment.default.rootURL;


    var MAX_QTY = 10;
    var ERR_INSUFFICIENT_INVENTORY = "ERR_INSUFFICIENT_INVENTORY";

    exports.default = Component.extend({
        basket: service(),
        rootURL: rootURL,

        _setup: on('init', function () {
            var maxQty = Math.min(MAX_QTY, this.get('item.product.available'));
            this.set('quantities', (0, _range.range)(1, maxQty));
        }),

        _didRender: on('didRender', function () {
            $(".selectpicker").selectpicker('refresh');
            $(".selectpicker").each(function () {
                var element = $(this);
                if (!element.hasClass("is-invalid")) {
                    element.parent().removeClass("is-invalid");
                }
            });
        }),

        actions: {
            updateBasketItem: function updateBasketItem(qty) {
                var _this = this;

                var basketItem = this.get('item.basketItem');

                this.set('availabilityError', null);

                if (!basketItem && !isEmpty(qty)) {
                    // Add as a new product item.
                    var product = this.get('item.product');
                    product.set('qty', qty);

                    return this.get('basket').addExtraProducts([product], this.get('bg.bookingGroup.bookingGroup')).then(function () {
                        return _this.get('basket').refresh();
                    }).catch(function (error) {
                        // TODO: Reload product to get the actual product.available and update quantities?
                        if (error.itemsCode === ERR_INSUFFICIENT_INVENTORY) {
                            _this.set('availabilityError', true);
                            _this.set('item.quantity', null);
                        }
                        return error;
                    });
                } else if (basketItem && isEmpty(qty)) {
                    // Remove existing product item.

                    return this.get('basket').removeItem(basketItem);
                } else if (basketItem && !isEmpty(qty)) {
                    // Update existing product item.

                    basketItem.set('quantity', qty);

                    return this.get('basket').updateItems([basketItem]).catch(function (error) {
                        // TODO: What to if neither the current quantity nor the previously selected quantity are available anymore?
                        // TODO: Should we automatically remove the items from the basket?
                        // TODO: Reload product to get the actual product.available and update quantities?

                        if (error.itemsCode === ERR_INSUFFICIENT_INVENTORY) {
                            _this.set('availabilityError', true);
                        }
                        return error;
                    });
                }
            }
        }
    });
});