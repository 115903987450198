define('customisation-web-public/pods/my-bookings/view/booking-details/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component;
    var service = Ember.inject.service;
    exports.default = Component.extend({
        modal: service(),

        actions: {
            showEditCustomizationModal: function showEditCustomizationModal(item) {
                var _this = this;

                this.get('modal').show('book/customize-product/edit-customization-modal', {
                    item: item,
                    save: function save(customization) {
                        return _this.get('saveCustomization')(item, customization).then(function () {
                            return _this.get('modal').hide();
                        });
                    }
                });
            }
        }
    });
});