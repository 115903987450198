define('customisation-web-public/pods/components/pagination-buttons/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component;
    var computed = Ember.computed;
    var isBlank = Ember.isBlank;
    exports.default = Component.extend({
        classNames: ['col-lg-12', 'no-padding'],
        classNameBindings: ['hidden'],

        hidden: computed('pageMeta', function () {
            return isBlank(this.get('pageMeta')) || this.get('pageMeta.first') && this.get('pageMeta.last');
        })
    });
});