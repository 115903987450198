define('customisation-web-public/models/customized-product', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model', 'customisation-web-public/models/basket-item-customization'], function (exports, _jsonapiModel, _basketItemCustomization) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var EmberObject = Ember.Object;
    var computed = Ember.computed;
    var copy = Ember.copy;
    var isPresent = Ember.isPresent;


    var CustomizedProduct = EmberObject.extend(Ember.Copyable, {
        id: null,
        status: null,
        bookingItemId: null,
        customization: null,

        isAmendable: computed('status', function () {
            return this.get('status') === 'AMENDABLE';
        }),
        isNotCancelled: computed('status', function () {
            return this.get('status') !== 'CANCELLED';
        }),
        statusClass: computed('status', function () {
            switch (this.get('status')) {
                case 'AMENDABLE':
                case 'INSTALLED':
                    return 'alert-success';
                case 'CANCELLED':
                    return 'alert-danger';
            }

            return 'alert-warning';
        }),

        fromJSON: function fromJSON() {
            var result = copy(this, true);

            if (isPresent(this.get('customization'))) {
                result.set('customization', _basketItemCustomization.default.convert(this.get('customization')));
            }

            return result;
        }
    });

    CustomizedProduct.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'customized-products'
        }
    });

    CustomizedProduct.reopen({
        copy: function copy() {
            return CustomizedProduct.create(this.getProperties(Object.keys(this)));
        }
    });

    exports.default = CustomizedProduct;
});