define('customisation-web-public/models/basket-item', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model', 'customisation-web-public/models/product', 'customisation-web-public/models/basket-item-customization'], function (exports, _jsonapiModel, _product, _basketItemCustomization) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var EmberObject = Ember.Object;
    var computed = Ember.computed;
    var copy = Ember.copy;
    var isPresent = Ember.isPresent;
    var isBlank = Ember.isBlank;


    var BasketItem = EmberObject.extend(Ember.Copyable, {
        id: null,
        created: null,
        name: null,
        product: null,
        customization: null,
        quantity: null,
        bookingGroup: null,
        unitPrice: null,
        price: null,
        discount: null,
        promotionDiscount: null,
        total: null,
        meta: null,
        productType: null,
        productGroup: null,
        deliveryOption: null,
        cancelled: null,
        status: null,
        itemsGroup: null,

        // transient (see toJSON())
        customizedProduct: null,

        customizationRequired: computed('meta.customizationFormId', function () {
            return isPresent(this.get('meta.customizationFormId'));
        }),
        size: computed('name', function () {
            return this.get('name').toLowerCase().indexOf("large") === -1 ? "regular" : "large";
        }),
        // TODO: Temporary, until we decide on subnames propagation.
        isExtra: computed('productType', function () {
            return this.get('productType') === 'EXTRA';
        }),
        isDelivery: computed('productGroup', function () {
            return this.get('productGroup') === 'Deliveries';
        }),
        isSoftPencil: computed('status', function () {
            return this.get('status') === 'SOFT_PENCIL';
        }),
        isCertificate: computed('name', function () {
            return this.get('name').includes("Certificate");
        }),

        toJSON: function toJSON() {
            var ret = copy(this, true);

            if (isBlank(this.get('customization'))) {
                ret.set('customization', undefined);
            }
            if (isBlank(this.get('deliveryOption'))) {
                ret.set('deliveryOption', undefined);
            }

            ret.set('customizedProduct', undefined);

            return ret;
        }
    });

    BasketItem.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'basket-items',
            relationships: {
                product: _product.default
            }
        },
        convert: function convert(json, group) {
            return BasketItem.create({
                id: json.id,
                created: isBlank(json.created) ? null : new Date(json.created),
                name: json.name,
                product: json.product,
                customization: isPresent(json.customization) ? _basketItemCustomization.default.convert(json.customization) : json.customization,
                quantity: json.quantity,
                bookingGroup: group,
                unitPrice: json.unitPrice,
                price: json.price,
                total: json.total,
                meta: json.meta,
                productType: json.productType,
                productGroup: json.productGroup,
                deliveryOption: json.deliveryOption,
                cancelled: json.cancelled,
                status: json.status,
                itemsGroup: json.itemsGroup
            });
        },
        convertArray: function convertArray(jsonArray, group) {
            var _this = this;

            return jsonArray.map(function (item) {
                return _this.convert(item, group);
            });
        }
    });

    BasketItem.reopen({
        copy: function copy() {
            return BasketItem.create(this.getProperties(Object.keys(this)));
        }
    });

    exports.default = BasketItem;
});