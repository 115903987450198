define('customisation-web-public/pods/book/payment-centre/route', ['exports', 'customisation-web-public/config/environment', 'customisation-web-public/models/payment-info', 'customisation-web-public/models/basket'], function (exports, _environment, _paymentInfo, _basket) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Route = Ember.Route;
    var inject = Ember.inject.service;
    var on = Ember.on;
    var set = Ember.set;
    var computed = Ember.computed;
    var assign = Ember.assign;
    var RSVP = Ember.RSVP;
    var SERVICES = _environment.default.SERVICES;
    exports.default = Route.extend({
        basket: inject(),
        session: inject(),
        ajax: inject(),
        stripe: inject(),

        _deactivate: on('deactivate', function () {
            this.get('stripe').close();
        }),

        hasAmount: computed('basket', function () {
            return this.get('basket.data.total') > 0;
        }),

        beforeModel: function beforeModel(transition) {
            var _this = this;

            var basketSetup = this.get('basket').setup(true).then(function () {
                return _this.validateBasketState(transition);
            }).then(function () {
                return _this.attachCustomer();
            }).then(function () {
                return _this.get('basket').refreshDeliveryProducts();
            });

            return RSVP.all([basketSetup, this.get('stripe').setup()]);
        },
        model: function model() {
            var paymentError = localStorage.getItem('paymentError');
            localStorage.removeItem('paymentError');

            return {
                rootURL: _environment.default.rootURL,
                inProgress: false,
                paymentInfo: _paymentInfo.default.create({ termsAccepted: false }),
                errors: null,
                paymentError: paymentError,
                hasAmount: this.get('hasAmount')
            };
        },
        validateBasketState: function validateBasketState(transition) {
            var target = null;

            if (!this.get('basket.data.hasProducts')) {
                target = 'book.select-products';
            } else if (!this.get('basket.data').isCustomizationsFilled()) {
                target = 'book.customize-product';
            } else if (!this.get('session.isAuthenticated')) {
                target = 'book.register-details';
            }

            if (target) {
                if (transition) {
                    transition.abort();
                }

                this.transitionTo(target);
                return RSVP.reject();
            }

            return RSVP.resolve();
        },
        attachCustomer: function attachCustomer() {
            var customer = this.get('session.currentCustomer');
            return this.get('basket').attachCustomer(customer);
        },
        getFinishUrl: function getFinishUrl() {
            var location = window.location.href;
            var match = /^.*?\w\//.exec(location);
            var prefix = match ? match[0] : location;
            return '' + prefix + this.get('router').generate('payment-callback');
        },
        cancelBasketBooking: function cancelBasketBooking(bookingId, error) {
            var _this2 = this;

            this.set('currentModel.inProgress', true);

            if (error) {
                localStorage.setItem('paymentError', error);
            }

            var paymentInfoData = _paymentInfo.default.stringify(_paymentInfo.default.create());

            return this.get('ajax').post(SERVICES.baskets + '/bookings/' + bookingId + '/cancel', paymentInfoData, _basket.default).then(function () {
                _this2.get('basket').refresh().then(function () {
                    return _this2.refresh();
                });
            }).catch(function () {
                return _this2.refresh();
            }).finally(function () {
                return _this2.set('currentModel.inProgress', false);
            });
        },


        actions: {
            makeStripePayment: function makeStripePayment(e) {
                var _this3 = this;

                e.preventDefault();

                if (this.get('currentModel.inProgress')) {
                    return;
                }

                this.set('currentModel.inProgress', true);

                var paymentInfo = this.get('currentModel.paymentInfo');
                var customer = this.get('session.currentCustomer');

                if (this.get('basket.data.total') > 0) {
                    this.set('currentModel.errors', null);

                    this.get('basket').checkout(paymentInfo).then(function (booking) {
                        var successUrl = (_environment.default.API_HOST + _environment.default.clientRootURL + "/booking-confirm/").replace(/([^:]\/)\/+/g, "$1");
                        var cancelUrl = (_environment.default.API_HOST + _environment.default.clientRootURL).replace(/([^:]\/)\/+/g, "$1");

                        var requestData = JSON.stringify({
                            clientId: booking.get('client.id'),
                            orderId: booking.get('id'),
                            successUrl: successUrl,
                            cancelUrl: cancelUrl,
                            description: 'Fund It Now',
                            address1: customer.get('mainAddress.address1'),
                            postCode: customer.get('mainAddress.postcode'),
                            amount: booking.get('amount'),
                            currency: booking.get('currency')
                        });

                        _this3.set('currentModel.inProgress', true);

                        _this3.get('ajax').rawRequest(SERVICES.payments + '/stripe/transaction-requests', {
                            method: 'POST',
                            contentType: 'application/json',
                            data: requestData
                        }).then(function (transactionJSON) {
                            var transaction = transactionJSON.attributes;

                            _this3.get('stripe').redirectToCheckout({
                                sessionId: transaction.merchantRelatedData.CheckoutSessionId
                            }).then(function (result) {
                                console.log("error = " + result);
                                // If `redirectToCheckout` fails due to a browser or network
                                // error, display the localized error message to your customer
                                // using `result.error.message`.
                            });
                        });
                    }).catch(function (errors) {
                        _this3.set('currentModel.errors', errors);
                        return errors;
                    }).finally(function () {
                        return _this3.set('currentModel.inProgress', false);
                    });
                } else {
                    this.get('basket').checkout(paymentInfo).then(function (booking) {
                        return _this3.transitionTo('booking-confirm', booking.id);
                    }).catch(function (errors) {
                        _this3.set('currentModel.errors', errors);
                        return errors;
                    }).finally(function () {
                        return _this3.set('currentModel.inProgress', false);
                    });
                }
            },
            makeFreeBooking: function makeFreeBooking(e) {
                var _this4 = this;

                e.preventDefault();

                if (this.get('currentModel.inProgress')) {
                    return;
                }

                this.set('currentModel.inProgress', true);

                var paymentInfo = this.get('currentModel.paymentInfo');

                this.get('basket').checkout(paymentInfo).then(function (booking) {
                    return _this4.transitionTo('booking-confirm', booking.id);
                }).catch(function (errors) {
                    _this4.set('currentModel.errors', errors);
                    return errors;
                }).finally(function () {
                    return _this4.set('currentModel.inProgress', false);
                });
            }
        }
    });
});