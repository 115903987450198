define('customisation-web-public/models/booking-checkout', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model', 'customisation-web-public/models/booking'], function (exports, _jsonapiModel, _booking) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var EmberObject = Ember.Object;


    var BookingCheckout = EmberObject.extend({
        id: null,
        booking: null,
        items: null,
        termsAccepted: null,
        amount: null
    });

    BookingCheckout.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'booking-checkouts',
            relationships: {
                booking: _booking.default
            }
        }
    });

    exports.default = BookingCheckout;
});