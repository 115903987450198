define('customisation-web-public/pods/book-addition/index/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Route = Ember.Route;
    exports.default = Route.extend({
        redirect: function redirect() {
            this.transitionTo('book-addition.select-products');
        }
    });
});