define('customisation-web-public/pods/book-addition/select-products/extra-item/component', ['exports', 'customisation-web-public/utils/range'], function (exports, _range) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Component = Ember.Component;
    var on = Ember.on;


    var MAX_QTY = 10;

    exports.default = Component.extend({
        tagName: 'tr',

        _setup: on('init', function () {
            var maxQty = Math.min(MAX_QTY, this.get('item.product.available'));
            this.set('quantities', (0, _range.range)(1, maxQty));
        })
    });
});