define('customisation-web-public/models/customization-form-details', ['exports', 'customisation-web-public/models/customization-form-element'], function (exports, _customizationFormElement) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var EmberObject = Ember.Object;
    var computed = Ember.computed;
    var copy = Ember.copy;
    var assign = Ember.assign;
    var isPresent = Ember.isPresent;


    var CustomizationFormDetails = EmberObject.extend(Ember.Copyable, {
        formElement: null,
        answer: null,

        // transient (see toJSON())
        errors: null,

        filled: computed('answer', function () {
            return isPresent(this.get('answer'));
        }),

        toJSON: function toJSON() {
            var ret = copy(this, true);
            ret.set('errors', undefined);
            return ret;
        }
    });

    CustomizationFormDetails.reopenClass({
        convert: function convert(json) {
            return CustomizationFormDetails.create({
                formElement: _customizationFormElement.default.convert(json.formElement),
                answer: json.answer
            });
        },
        convertArray: function convertArray(jsonArray) {
            var _this = this;

            return jsonArray.map(function (item) {
                return _this.convert(item);
            });
        }
    });

    CustomizationFormDetails.reopen({
        copy: function copy(deep) {
            var ret = CustomizationFormDetails.create(this.getProperties(Object.keys(this)));
            if (deep) {
                // Make sure the answer.N.value strings are copied.
                ret.set('answer', this.get('answer').map(function (a) {
                    return assign({}, a, { value: a.value.slice(0) });
                }));
            }
            return ret;
        }
    });

    exports.default = CustomizationFormDetails;
});