define('customisation-web-public/services/session', ['exports', 'ember-simple-auth/services/session', 'customisation-web-public/config/environment', 'customisation-web-public/models/customer'], function (exports, _session, _environment, _customer) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service;
    var A = Ember.A;
    var observer = Ember.observer;
    var on = Ember.on;
    var getOwner = Ember.getOwner;
    var RSVP = Ember.RSVP;
    var SERVICES = _environment.default.SERVICES,
        AUTHENTICATOR = _environment.default.AUTHENTICATOR;
    exports.default = _session.default.extend({
        ajax: service(),

        _setupCustomSessionEvents: on('init', function () {
            var _this = this,
                _arguments = arguments;

            A(['authorizationFailed']).forEach(function (event) {
                _this.on(event, function () {
                    return _this[event].apply(_this, _arguments);
                });
            });
        }),
        _setupAuthorizationErrorHandler: on('init', function () {
            var _this2 = this;

            RSVP.on('error', function ( /*jqXHR*/error) {
                if (_this2.get('ajax').isAuthorizableRequest(error) && error.status === 401) {
                    _this2.trigger('authorizationFailed', error);
                }
            });
        }),

        setup: function setup() {
            var _this3 = this;

            var forceReload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

            if (!forceReload && this.get('_setupPromise')) {
                return this.get('_setupPromise');
            }

            if (!this.get('isAuthenticated')) {
                return null;
            }

            var setupPromise = new RSVP.Promise(function (resolve, reject) {
                _this3.set('currentCustomer', _customer.default.create());

                _this3.get('ajax').fetchModel(_customer.default, SERVICES.customers + '/me').then(function (customer) {
                    _this3.refreshCustomer(customer);
                    resolve(customer);
                }, function (error) {
                    return reject(error);
                });
            });

            this.set('_setupPromise', setupPromise);

            return setupPromise;
        },
        authenticateCustomer: function authenticateCustomer(credentials) {
            var noTransitionOnSuccess = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

            this.set('noTransitionOnSessionDataUpdated', noTransitionOnSuccess);

            return this.authenticate(AUTHENTICATOR, credentials);
        },

        // NOTE: Rather a hack - may break on Ember-Simple-Auth or Ember-Simple-Auth-Token update.
        authenticateCustomerWithToken: function authenticateCustomerWithToken(token) {
            var noTransitionOnSuccess = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

            var authenticator = getOwner(this).lookup(AUTHENTICATOR);
            var tokenData = authenticator.getTokenData(token);

            this.set('noTransitionOnSessionDataUpdated', noTransitionOnSuccess);

            this.get('store').trigger('sessionDataUpdated', {
                authenticated: {
                    authenticator: AUTHENTICATOR,
                    token: token,
                    exp: tokenData[authenticator.get('tokenExpireName')]
                }
            });
        },


        setCurrentCustomer: observer('isAuthenticated', function () {
            var authenticated = this.get('isAuthenticated');

            if (authenticated && !this.get('currentCustomer')) {
                // Logged in and the currentCustomer has not yet been initialized (has not yet started the initialisation).
                // Actually, this should never happen.

                this.setup();
            } else if (!authenticated) {
                // Logged out.

                this.set('currentCustomer', null);
            }
        }),

        refreshCustomer: function refreshCustomer(customer) {
            if (customer) {
                this.set('currentCustomer', customer);

                this.trigger('customerRefreshSucceeded');
            }
        },
        authorizationFailed: function authorizationFailed() {
            this.invalidate();
        }
    });
});