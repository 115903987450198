define('customisation-web-public/models/customization-form', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model', 'customisation-web-public/models/project', 'customisation-web-public/models/customization-form-element'], function (exports, _jsonapiModel, _project, _customizationFormElement) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var EmberObject = Ember.Object;
    var copy = Ember.copy;


    var CustomizationForm = EmberObject.extend(Ember.Copyable, {
        id: null,
        created: null,
        project: null,
        name: null,
        active: null,
        elements: null,
        requirements: null,
        editablePeriod: null,
        previewTemplate: null,

        toJSON: function toJSON() {
            var result = copy(this, true);
            result.set('editablePeriod', parseInt(this.get('editablePeriod')));
            return result;
        }
    });

    CustomizationForm.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'customization-forms',
            relationships: {
                project: _project.default,
                elements: _customizationFormElement.default
            }
        }
    });

    CustomizationForm.reopen({
        copy: function copy() {
            return CustomizationForm.create(this.getProperties(Object.keys(this)));
        }
    });

    exports.default = CustomizationForm;
});