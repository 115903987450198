define('customisation-web-public/utils/urls', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.buildFilterUrl = buildFilterUrl;
    var isBlank = Ember.isBlank;


    var _appendMapParams = function _appendMapParams(url, mapName, params) {
        var paramsString = '';

        for (var key in params) {
            if (params.hasOwnProperty(key) && !isBlank(params[key])) {
                paramsString += '' + (paramsString ? '&' : '') + mapName + '[' + key + ']=' + params[key];
            }
        }

        if (paramsString) {
            paramsString = '' + (url.indexOf('?') === -1 ? '?' : '&') + paramsString;
        }

        return '' + url + paramsString;
    };

    function buildFilterUrl(baseUrl) {
        var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        return _appendMapParams(baseUrl, 'filter', params);
    }
});