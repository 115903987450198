define('customisation-web-public/pods/book/customize-product/basket-item', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var EmberObject = Ember.Object;
    var computed = Ember.computed;


    var BasketItem = EmberObject.extend({
        basketItem: null,
        product: null,
        quantity: null,
        minDeliveryPrice: null,
        customizationForm: null,

        isMain: computed('product.productType', function () {
            return this.get('product.productType') === 'PRODUCT';
        }),
        isExtra: computed('product.productType', function () {
            return this.get('product.productType') === 'EXTRA';
        }),
        isCertificate: computed('product.productType', function () {
            return this.get('product.name').includes('Certificate');
        })
    });

    exports.default = BasketItem;
});