define('customisation-web-public/models/customer', ['exports', 'ember-sf-jsonapi-model/mixins/jsonapi-model', 'customisation-web-public/models/customer-address', 'customisation-web-public/models/client', 'moment'], function (exports, _jsonapiModel, _customerAddress, _client, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var EmberObject = Ember.Object;
    var computed = Ember.computed;
    var assign = Ember.assign;
    var copy = Ember.copy;
    var isBlank = Ember.isBlank;


    var __addAddressIfRequired = function __addAddressIfRequired(addresses, type) {
        if (!addresses.isAny('type', type)) {
            addresses.pushObject(_customerAddress.default.create({
                type: type,
                default: true
            }));
        }
    };
    var __initAddresses = function __initAddresses(customer) {
        var addresses = customer.get('addresses') || [];
        __addAddressIfRequired(addresses, 'MAIN');
        __addAddressIfRequired(addresses, 'BILLING');
        customer.set('addresses', addresses.map(function (a) {
            return _customerAddress.default.create(a);
        }));
    };

    var Customer = EmberObject.extend(Ember.Copyable, {
        id: null,
        client: null,
        title: null,
        firstName: null,
        lastName: null,
        addresses: null,
        email: null,
        company: null,
        phone: null,
        dateOfBirth: null,
        active: null,
        optin: null,
        subscription: null,

        // Used for customer registration & password change.
        currentPassword: null,
        newPassword: null,
        confirmPassword: null,

        init: function init() {
            this._super.apply(this, arguments);
            __initAddresses(this);
        },


        fullName: computed('firstName', 'lastName', function () {
            return this.get('firstName') + ' ' + this.get('lastName');
        }),

        mainAddress: computed('addresses', function () {
            return this.get('addresses').findBy('type', 'MAIN');
        }),
        billingAddress: computed('addresses', function () {
            return this.get('addresses').findBy('type', 'BILLING');
        }),

        fromJSON: function fromJSON() {
            var ret = copy(this, true);

            var dateOfBirth = this.get('dateOfBirth');
            ret.set('dateOfBirth', isBlank(dateOfBirth) ? null : (0, _moment.default)(dateOfBirth, 'YYYY-MM-DD').toDate());

            var optin = this.get('optin');
            ret.set('subscription', true === optin ? 'SUBSCRIBED' : 'NO');

            __initAddresses(ret);

            return ret;
        },
        toJSON: function toJSON() {
            var ret = copy(this, true);
            var addresses = this.get('addresses').map(function (a) {
                return copy(a, true);
            });

            // Replicate main address into billing address (hopefully, temporarily).
            var mainAddress = addresses.filter(function (a) {
                return a.get('default');
            }).findBy('type', 'MAIN');
            var billingAddress = addresses.filter(function (a) {
                return a.get('default');
            }).findBy('type', 'BILLING');
            if (!mainAddress.isEmpty()) {
                billingAddress.setProperties(assign({}, mainAddress, {
                    id: undefined,
                    type: 'BILLING'
                }));
            }

            ret.set('dateOfBirth', isBlank(this.get('dateOfBirth')) ? null : (0, _moment.default)(this.get('dateOfBirth')).format('YYYY-MM-DD'));
            ret.set('addresses', addresses.filter(function (a) {
                return !a.isEmpty();
            }));
            if (!isBlank(this.get('subscription'))) {
                ret.set('optin', this.get('subscription') === 'YES' || this.get('subscription') === 'SUBSCRIBED');
            }
            return ret;
        },


        statusClass: computed('active', function () {
            return this.get('active') ? 'is-active' : 'disabled';
        }),
        statusTextClass: computed('status', function () {
            return this.get('active') ? 'green' : 'blue';
        })
    });

    Customer.reopen({
        copy: function copy() {
            return Customer.create(this.getProperties(Object.keys(this)));
        }
    });

    Customer.reopenClass(_jsonapiModel.default, {
        meta: {
            type: 'customers',
            relationships: {
                client: _client.default
            }
        }
    });

    exports.default = Customer;
});