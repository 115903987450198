define('customisation-web-public/services/ajax', ['exports', 'customisation-web-public/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var run = Ember.run;
    var EmberError = Ember.Error;
    var Service = Ember.Service;
    var service = Ember.inject.service;
    var typeOf = Ember.typeOf;
    var isBlank = Ember.isBlank;
    var $ = Ember.$;
    var RSVP = Ember.RSVP;
    var API_HOST = _environment.default.API_HOST;


    var AUTHORIZABLE_REQUEST_MARK = '__sf_jsonapi_authorized__';

    var __setField = function __setField(object, fieldPath, value) {
        if (fieldPath.charAt(0) === '/') {
            fieldPath = fieldPath.slice(1);
        }

        var path = fieldPath.split('/');

        // Walk through path minus last field.
        var i = 0;
        for (; i < path.length - 1; i++) {
            var field = path[i];

            if (!object.hasOwnProperty(field)) {
                object[field] = {};
            }
            object = object[field];
        }

        // Set last (target) field.
        object[path[i]] = value;
    };
    var __convertAndResolvePromise = function __convertAndResolvePromise(promise, resolve, reject, modelClass) {
        return promise.then(function (response) {
            if (typeOf(modelClass) === 'class') {
                var responseData = response['data'];

                resolve(typeOf(responseData) === 'array' ? modelClass.fromJsonArray(responseData, response['included']) : modelClass.fromJson(responseData, response['included']));
            } else {
                resolve(response);
            }
        }, function (errors) {
            return reject(errors);
        });
    };

    exports.default = Service.extend({
        session: service(),

        CONTENT_TYPE_JSON_API: 'application/vnd.api+json; charset=utf-8',

        isAuthorizableRequest: function isAuthorizableRequest(jqXHR) {
            return !!jqXHR[AUTHORIZABLE_REQUEST_MARK];
        },
        rawRequest: function rawRequest(url, options) {
            var _this = this;

            if (!this.get('session.isAuthenticated')) {
                // If not authenticated - do a request without authorization headers.

                return this._rawRequest(url, options, false);
            } else {
                // Authorizable request.

                return new RSVP.Promise(function (resolve, reject) {
                    _this.get('session').authorize('authorizer:custom', function (headerName, headerValue) {
                        var optionsWithHeaders = _this._optionsWithAuthorizationHeaders(options, headerName, headerValue);

                        /* TODO
                         * Possible options to handle authorization errors:
                         *     - options.beforeSend - mark request here and then elsewhere handle errors for marked requests only
                         *     - options.statusCode - set handlers for particular status codes here, in this service
                         *     - $(document).ajaxError() - if required, for plain jQuery calls (not from this service)
                         */

                        _this._rawRequest(url, optionsWithHeaders).then(function (response) {
                            return resolve(response);
                        }).catch(function (error) {
                            return reject(error);
                        });
                    });
                });
            }
        },


        // get json
        request: function request(url, data) {
            return this.rawRequest(url, {
                contentType: this.CONTENT_TYPE_JSON_API,
                data: data
            });
        },


        // post json data with optional conversion of the result
        // (create entity)
        post: function post(url, data, modelClass) {
            var _this2 = this;

            return new RSVP.Promise(function (resolve, reject) {
                __convertAndResolvePromise(_this2.rawRequest(url, {
                    method: 'POST',
                    contentType: _this2.CONTENT_TYPE_JSON_API,
                    data: data
                }), resolve, reject, modelClass);
            });
        },


        // post multipart data with optional conversion of the result
        postMultipart: function postMultipart(url, data, modelClass) {
            var _this3 = this;

            return new RSVP.Promise(function (resolve, reject) {
                __convertAndResolvePromise(_this3.rawRequest(url, {
                    method: 'POST',
                    cache: false,
                    processData: false,
                    contentType: false,
                    data: data
                }), resolve, reject, modelClass);
            });
        },


        // patch json data with optional conversion of the result
        // (update entity)
        patch: function patch(url, data, modelClass) {
            var _this4 = this;

            return new RSVP.Promise(function (resolve, reject) {
                __convertAndResolvePromise(_this4.rawRequest(url, {
                    method: 'PATCH',
                    contentType: _this4.CONTENT_TYPE_JSON_API,
                    data: data
                }), resolve, reject, modelClass);
            });
        },
        remove: function remove(url, data, modelClass) {
            var _this5 = this;

            return new RSVP.Promise(function (resolve, reject) {
                __convertAndResolvePromise(_this5.rawRequest(url, {
                    method: 'DELETE',
                    contentType: _this5.CONTENT_TYPE_JSON_API,
                    data: data
                }), resolve, reject, modelClass);
            });
        },


        // TODO: Doesn't support AUTHORIZABLE_REQUEST_MARK and so doesn't redirect to login page on 401
        downloadAttachment: function downloadAttachment(url) {
            var _this6 = this;

            var defaultFilename = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
            var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'application/octet-stream';

            return new RSVP.Promise(function (resolve, reject) {
                var xhr = new XMLHttpRequest();
                xhr.open('GET', url, true);
                xhr.responseType = 'arraybuffer';
                xhr.onload = function () {
                    if (this.status === 200) {
                        var filename = defaultFilename;
                        var disposition = xhr.getResponseHeader('Content-Disposition');
                        if (disposition && disposition.indexOf('attachment') !== -1) {
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(disposition);
                            if (matches && matches[1]) {
                                filename = matches[1].replace(/['"]/g, '');
                            }
                        }
                        // var type = xhr.getResponseHeader('Content-Type');
                        // var blob = new Blob([this.response], { type: type });
                        var blob = new Blob([this.response], { type: type });

                        saveAs(blob, filename);

                        /*
                        if (typeof window.navigator.msSaveBlob !== 'undefined') {
                            // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which
                            // they were created. These URLs will no longer resolve as the data backing the URL has been freed."
                            window.navigator.msSaveBlob(blob, filename);
                        } else {
                            const URL = window.URL || window.webkitURL;
                            const downloadUrl = URL.createObjectURL(blob);
                             if (filename) {
                                // use HTML5 a[download] attribute to specify filename
                                const a = document.createElement("a");
                                // safari doesn't support this yet
                                if (typeof a.download === 'undefined') {
                                    window.location = downloadUrl;
                                } else {
                                    a.href = downloadUrl;
                                    a.download = filename;
                                    document.body.appendChild(a);
                                    a.click();
                                }
                            } else {
                                window.location = downloadUrl;
                            }
                             setTimeout(function () { URL.revokeObjectURL(downloadUrl); }, 100); // cleanup
                        }
                        */
                    }

                    resolve(xhr);
                };
                xhr.onerror = function () {
                    reject(xhr);
                };
                xhr.onabort = function () {
                    reject('aborted');
                };

                if (_this6.get('session.isAuthenticated')) {
                    _this6.get('session').authorize('authorizer:custom', function (headerName, headerValue) {
                        xhr.setRequestHeader(headerName, headerValue);
                        xhr.send();
                    });
                } else {
                    xhr.send();
                }
            });
        },


        // get json and convert it into model object
        fetchModel: function fetchModel(modelClass, url) {
            var _this7 = this;

            if (!modelClass || !modelClass.hasOwnProperty('fromJson')) {
                throw new EmberError('modelClass must implement fromJson() method');
            }

            return new RSVP.Promise(function (resolve, reject) {
                _this7.request(url).then(function (responseData) {
                    resolve(modelClass.fromJson(responseData['data'], responseData['included']));
                }, function (xhr) {
                    reject(xhr);
                });
            });
        },


        // get json page and convert it into an array of model objects + page meta
        fetchPage: function fetchPage(modelClass, url, pageParams, filterParams) {
            var _this8 = this;

            if (!modelClass || !modelClass.hasOwnProperty('fromJsonArray')) {
                throw new EmberError('modelClass must implement fromJsonArray() method');
            }

            url = this._appendMapParams(url, 'page', {
                number: pageParams.page ? pageParams.page - 1 : null,
                size: pageParams.size ? pageParams.size : null,
                totals: !this._isEmptyFilterParams(filterParams)
            });
            url = this._appendMapParams(url, 'filter', filterParams);

            return new RSVP.Promise(function (resolve, reject) {
                _this8.request(url).then(function (responseData) {
                    resolve({
                        data: modelClass.fromJsonArray(responseData['data'], responseData['included']),
                        meta: responseData['meta'],
                        links: responseData['links']
                    });
                }, function (xhr) {
                    reject(xhr);
                });
            });
        },


        // get json and convert it into model object
        fetchList: function fetchList(modelClass, url) {
            var _this9 = this;

            if (!modelClass || !modelClass.hasOwnProperty('fromJsonArray')) {
                throw new EmberError('modelClass must implement fromJsonArray() method');
            }

            // TODO: Dirty solution (hopefully temporal)
            url = this._appendMapParams(url, 'page', {
                size: 10000
            });

            return new RSVP.Promise(function (resolve, reject) {
                _this9.request(url).then(function (responseData) {
                    resolve(modelClass.fromJsonArray(responseData['data'], responseData['included']));
                }, function (xhr) {
                    reject(xhr);
                });
            });
        },
        _appendMapParams: function _appendMapParams(url, mapName, params) {
            var paramsString = '';

            for (var key in params) {
                if (params.hasOwnProperty(key) && !isBlank(params[key])) {
                    paramsString += '' + (paramsString ? '&' : '') + mapName + '[' + key + ']=' + params[key];
                }
            }

            if (paramsString) {
                paramsString = '' + (url.indexOf('?') === -1 ? '?' : '&') + paramsString;
            }

            return '' + url + paramsString;
        },
        _isEmptyFilterParams: function _isEmptyFilterParams(params) {
            if (isBlank(params)) {
                return true;
            }

            for (var key in params) {
                if (params.hasOwnProperty(key) && !isBlank(params[key])) {
                    return false;
                }
            }

            return true;
        },
        _rawRequest: function _rawRequest(url, options) {
            var _this10 = this;

            var authorizable = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

            return new RSVP.Promise(function (resolve, reject) {
                $.ajax(_this10._createOptions(url, options, authorizable)).then(function (response) {
                    run(function () {
                        resolve(response);
                    });
                }, function (xhr /*, status, error*/) {
                    run(function () {
                        if (xhr.responseText) {
                            var jsonApiErrors = JSON.parse(xhr.responseText).errors;
                            reject(_this10._convertJsonApiErrors(jsonApiErrors));
                        } else {
                            reject(xhr);
                        }
                    });
                });
            });
        },
        _convertJsonApiErrors: function _convertJsonApiErrors(jsonApiErrors) {
            var result = {};

            jsonApiErrors.forEach(function (error) {
                var pointer = error.source.pointer.replace(/\/data\/(attributes|relationships)\//, '');

                if (isBlank(pointer)) {
                    pointer = '_global';
                }

                // TODO: Replace with object? { code: "", message: "" }
                // TODO: Add support for multiple errors for single pointer.
                __setField(result, pointer, error.title);
                __setField(result, pointer + 'Code', error.code);
            });

            return result;
        },
        _optionsWithAuthorizationHeaders: function _optionsWithAuthorizationHeaders(options, headerName, headerValue) {
            var headers = {};
            headers[headerName] = headerValue;
            headers = $.extend({}, options.headers, headers);

            return $.extend({}, options, { headers: headers });
        },
        _createOptions: function _createOptions(url) {
            var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
            var authorizable = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

            return $.extend({
                url: this._buildUrl(url),
                method: 'GET',
                dataType: 'json',
                // statusCode: {
                //     401: function () {
                //         Authorization error handling here.
                //             This may even be set up in the config/environment.js
                //     }
                // },
                beforeSend: function beforeSend(jqXHR) {
                    if (authorizable) {
                        jqXHR[AUTHORIZABLE_REQUEST_MARK] = true;
                    }
                }
            }, options);
        },
        _buildUrl: function _buildUrl(url) {
            if (!(url.indexOf('http://') === 0 || url.indexOf('https://') === 0)) {
                var host = API_HOST;

                // Append trailing '/' to the host.
                if (host.charAt(host.length - 1) !== '/') {
                    host = host + '/';
                }

                // Remove heading '/'.
                if (url.charAt(0) === '/') {
                    url = url.slice(1);
                }

                url = host + url;
            }

            return url;
        }
    });
});